import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './component/login/login.component';
import { PageNotfoundComponent } from './component/page-notfound/page-notfound.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { AuthGuard } from '@services/auth.guard';
import { ChangepasswordComponent } from './component/changepassword/changepassword.component';
import { ChangepasswordDetailComponent } from './component/changepassword-detail/changepassword-detail.component';
import { PaymentComponent } from './component/payment/payment.component';
import { PaymentDetailComponent } from './component/payment-detail/payment-detail.component';
import { PaymentInfoComponent } from './component/payment_info/payment_info.component';
import { PaymentDetailInfoComponent } from './component/payment_info_detail/payment-detail-info.component';
import { SystemMainteneceComponent }  from './component/system-maintenece/system-maintenece.component';
import { CommonModule } from '@angular/common';
import { PaymentModifyComponent } from './component/payment-modify/payment-modify.component';

import { BanktypeComponent } from './component/banktype/banktype.component';
import { BanktypeDetailComponent } from './component/banktype-detail/banktype-detail.component';
import { BanktypeInfoComponent } from './component/banktype-info/banktype-info.component';

import { CountryComponent } from './component/country-pages/country/country.component';
import { CountryDetailComponent } from './component/country-pages/country-detail/country-detail.component';
// import { ChatComponentComponent } from './component/chat-component/chat-component.component';



const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },  
  {
    path: 'login', component: LoginComponent
  }, 
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]
  },
  {
    path: "agent",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/agent-pages/agent-pages.module').then((m) => m.AgentPagesModule)
  },
  {

    path: "usrlevel",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/user-level-pages/user-level-pages.module').then((m) => m.UserLevelPageModule)
  },     
  {
    path: "country",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/country-pages/country-pages.module').then((m) => m.CountryPagesModule)
    
  },
  {
    path: "agent-level",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/agent-level-pages/agent-level-pages.module').then((m) => m.AgentLevelPagesModule)
    
  },
  {
    path: "level",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/level-pages/level-pages.modules').then((m) => m.LevelPagesModule)
    
  },
  {
    path: "exchange",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/exchange-pages/exchange-pages.module').then((m) => m.ExchangePagesModule)    
  },
  {
    path: "admin",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/admin-pages/admin-pages.module').then((m) => m.AdminPagesModule)
  },
  {
    path: "transfer",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/transfer-pages/transfer-pages.module').then((m) => m.TransferPagesModule)
  },
  {
    path: "template",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/template-pages/template-pages.module').then((m) => m.TemplatePagesModule)
  },
  {
    path: "point",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/point-pages/point-pages.modules').then((m) => m.PoinPagesModule)
  },
  {
    path: "promotion-ads",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/promotion-ads-pages/promotion-ads-pages.module').then((m) => m.PromotionAdsPagesModule)
  }, 
  {
    path: "promotion",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/promotion-pages/promotion-pages.module').then((m) => m.PromotionPagesModule)
  },
  {
    path: "tutorial",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/tutorial-pages/tutorial-pages.module').then((m) => m.TutorialPagesModule)
  },    
  {
    path: "user",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/user-management-pages/user-management-pages.module').then((m) => m.UserManagementPagesModule)
  },  
  {
    path: "group",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/group-pages/group-pages.module').then((m) => m.GroupPagesModule)
  }, 
  {
    path: "ads",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/ads-pages/ads-pages.module').then((m) => m.AdsPagesModule)
  }, 
  {
    path: "notification",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/notification-pages/notification-pages.module').then((m) => m.NotificationPagesModule)
  }, 
  {
    path: "report",
    canActivate: [AuthGuard],     
    loadChildren: () => import('@components/reporting-pages/reporting-pages.module').then((m) => m.ReportingPagesModule)
  }, 
  {
    path: 'changepassword-list', component: ChangepasswordComponent, canActivate: [AuthGuard]
  },
  {
    path: 'changepassword-detail/:id', component: ChangepasswordDetailComponent, canActivate: [AuthGuard]
  },
  
  {
    path: 'payment-list', component: PaymentComponent, canActivate: [AuthGuard]
  },
  {
    path: 'payment-detail', component: PaymentDetailComponent, canActivate: [AuthGuard]
  },
  {
    path: 'payment-detail/:id', component: PaymentDetailComponent, canActivate: [AuthGuard]
  },  
  
  
  {
    path: 'payment-info', component: PaymentInfoComponent, canActivate: [AuthGuard]
  },
  {
    path: 'payment-info-detail', component: PaymentDetailInfoComponent, canActivate: [AuthGuard]
  },
  {
    path: 'payment-info-detail/:id', component: PaymentDetailInfoComponent, canActivate: [AuthGuard]
  },
  {
    path: 'payment-info-modify/:id', component: PaymentModifyComponent, canActivate: [AuthGuard]
  }, 
  
  
  {
    path: 'system-maintence/:message', component: SystemMainteneceComponent
  },  
  
  {
    path: 'banktype', component: BanktypeComponent, canActivate: [AuthGuard]
  },  
  {
    path: 'banktype-detail/:id', component: BanktypeDetailComponent, canActivate: [AuthGuard]
  }  , 
  {
    path: 'banktype-info', component: BanktypeInfoComponent, canActivate: [AuthGuard]
  }  ,   
  {
    path: '**', component: PageNotfoundComponent
  }
  
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
