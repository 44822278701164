import { Component } from '@angular/core';
import { Router,NavigationEnd  } from '@angular/router';
import { ChatService } from '../app/service/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SecureKyat';
  showHeader: boolean = false;
  constructor(private router: Router,private chatService: ChatService) {     
   this.router.events.subscribe(
    (event: any) => {
      if (event instanceof NavigationEnd) {        
        if(event.url.includes('/login') || event.url== '/') this.showHeader = true;
        else this.showHeader = false;
      }
    }
  );  
  }
  get isChatVisible() {
    return this.chatService.chatVisible$;
  }
}



