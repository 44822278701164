import { Component, OnInit,TemplateRef } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { FunctService } from '@services/funct.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiTokenService } from '@services/api-token.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';



declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  model: any;
  modalRef: BsModalRef;
  detaiObj: any;
  adminDTO;
  isOwnerLoggedIn;  
  resultDTO: any;  
  ratemodel : any;  
  userDTO: any;
  total_active_users: any;
  pendingcount : any;
  approvedcount : any;
  deniedcount : any;
  idIndex: any;
  
  constructor(
     private spinner: NgxSpinnerService, 
     private toastr: ToastrService,
     private funct: FunctService, 
     private apiTokenServer: ApiTokenService,
     private modalService: BsModalService) {
    this.getAdminProfile();
    this.search();
    this.latestRate();    
  }

  ngOnInit(): void {
    if (!localStorage.getItem('loadFlag')) {
      localStorage.setItem('loadFlag', 'noLoad');
 
    }
    else {
      localStorage.removeItem('loadFlag');
      localStorage.removeItem
    }
   
  }

  ngAfterViewInit() {
  }
  OkLogout() {
    localStorage.removeItem("token_en");
    localStorage.removeItem("isOwnerLoggedIn");    
    window.location.href = "./login";
  }

  async getAdminProfile() {
 
    //call from local storage
   var  admindataexist = await JSON.parse(localStorage.getItem('adminDTO')); 
    console.log(admindataexist)   
    let isOwnerLoggedIn = localStorage.getItem('isOwnerLoggedIn');
    if (admindataexist != undefined && admindataexist != null) {
      if (isOwnerLoggedIn != undefined && isOwnerLoggedIn) {
        if (admindataexist != undefined && admindataexist != null) {
          if (admindataexist.role_name == "SUPER_ADMIN" || admindataexist.role_name == "OWNER") {
            this.isOwnerLoggedIn = true;
          }
          else {
            this.isOwnerLoggedIn = false;
          }
        }
      }
    }
    else {      
      await this.apiTokenServer.get(`${this.funct.ipaddress}authenticate/adminProfile`,true)
        .then(async response => {          
            this.adminDTO = JSON.parse(response.data.data);
            localStorage.setItem("adminDTO", response.data.data);  
            localStorage.setItem("role_name",this.adminDTO.role_name);   
            window.location.reload();      
            this.spinner.hide();
        })
        .catch(async error => {
          console.log(error.status)
          
          return false;
        });
    }



  }

  
  latestRate(){
  
  let model = {
    from:1,
    to:2
  };
  let modelStr= JSON.stringify(model); 
  this.apiTokenServer.post(this.funct.ipaddress + 'exchangerate/get-latest-rate-bycountry', modelStr,true,true)
  .then(res => {   
    if(res.data.data != null ){
      this.ratemodel = JSON.parse(res.data.data); 
    }
    
  })
  .catch(error => {

  });
 }

  search() {
    this.apiTokenServer.get(`${this.funct.ipaddress}dashboard/dashboard-data`,true)
    .then(response => {       
      var result = JSON.parse(response.data.data);        
      this.pendingcount = result["pendingcount"];
      this.total_active_users =result["active_user_count"];
      this.approvedcount = result["approvedcount"];
      this.deniedcount = result["deniedcount"];    

      
    })
    .catch(error => {
      
    });

   


   
    
  }

 

  ngOnDestroy(): void {
  
  }

}
