<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
          <div class="col-sm-8">
            <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Change Password </h3>
          </div>
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
  
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">
  
            <div class="form-group col-md-2">
              <label>Name</label>
              <input type="text" class="form-control form-control-sm" [(ngModel)]="model.name" [ngModelOptions]="{standalone: true}">
            </div>
  
            <div class="form-group col-md-2">
              <label>Phone No</label>
              <input type="tel" class="form-control form-control-sm" [(ngModel)]="model.phone_no" [ngModelOptions]="{standalone: true}">
            </div>
  
            <div class="form-group col-md-2">
              <label>Referral Code</label>
              <input type="text" class="form-control form-control-sm" [(ngModel)]="model.referralCode" [ngModelOptions]="{standalone: true}">
            </div>
  
            <div class="form-group col-md-2">
              <label>Status</label>
              <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="model.status" [ngModelOptions]="{standalone: true}">
                <option value="">All</option>
                <option value="ACTIVE" selected>Active</option>
                <option value="INACTIVE">Inactive</option>
               
              </select>
            </div>
  
            <div class="form-group col-md-2 col-sm-4 col-xs-4">
              <label></label>
              <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="search()">Search</button>
            </div>
            
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Change Password List </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <table width='100%' id="tblAdmin" class="table table-bordered table-hover">
                    <thead>
                    <tr>
                      <th style="font-family: unicode;">Name</th>
                      <th style="font-family: unicode;">Phone No</th>
                      <th style="font-family: unicode;">Role</th>
                      <th style="font-family: unicode;">Referral Code</th>
                      <th style="font-family: unicode;">Status</th>
                      <th style="font-family: unicode;">Updated Date</th>
                      <th style="font-family: unicode;">Updated By</th>
                      <th style="font-family: unicode;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let admin of adminList">
                      <td style="font-family: unicode;">{{admin.name}}</td>
                      <td style="font-family: unicode;">{{admin.phone_no}}</td>
                      <td style="font-family: unicode;">{{admin.role_name}}</td>
                      <td style="font-family: unicode;">{{admin.referral_code}}</td>
                      <td style="font-family: unicode;">{{admin.status}}</td>
                      <td style="font-family: unicode;">{{admin.updated_date_string}}</td>
                      <td style="font-family: unicode;">{{admin.updated_by_name}}</td>
                      <td style="font-family: unicode;"><a [routerLink]="['/changepassword-detail', admin.id]">
                        <i class="fas fa-edit" style="color:#4b88eb"></i>
                      </a>
                      </td>
                      <!-- <td style="font-family: unicode;"><a [routerLink] = "['/changepassword-detail']" [queryParams]="{id: admin.id}"><i class="fas fa-edit" style="color:#4b88eb"></i></a>
                      </td> -->
                    </tr>  
                    <tr *ngIf="adminList.length == 0 ">
                      <td colspan="12">
                       <div class="d-flex justify-content-center"> No data available in table </div>
                      </td>
                    </tr>                        
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
  
          </div>
        </form>
        <!-- /.row -->
  
        <!-- /.row -->
      </div><!--/. container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  