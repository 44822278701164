
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--  <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"> Promotion and News </h1>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>

                    <div class="row">
                        <div class="col-md-12">

                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Bank Account Modify </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>
                                        
                                        <div class="row">
                                            <label class="col-md-2"> Country </label>
                                            <div class="form-group col-md-3">
                                                <div class="form-group clearfix">
                                                    <select (change) = "fromCountry()" id = "country_id" class="form-control form-control-sm"
                                                        [(ngModel)]="model.country_id" [ngModelOptions]="{standalone: true}">
                                                        <option *ngFor="let country of CountryList" value = "{{country.id}}" >
                                                        {{country.code}}
                                                        </option>
                                                </select>
                                                   
                                                </div>
                                            </div>
                                            <div class = "col-md-1"></div>

                                            <label class="col-md-2">Bank Type</label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div id="paymentId" class="col-md-6" align="left">
                                                        <div class = "row">
                                                        <a *ngFor = "let item of paymentList">
                                                            <div class="col-lg-3">                                                               
                                                                <img [ngClass]="model.bankType_id==item.id?'ActiveLogo' : 'bankLogo'" 
                                                                (click) ="changeBankType(item.id,item.name)" [src]="item.logo_url" width = "60px" hegith = "60px" 
                                                               >
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                    <div id="paymentId1" class="col-md-6"  align="end">
                                                    </div>
                                                </div>
                                            </div>

                                           
                                         
                                            
                                        </div>
                                        <div class="row">
                                            <label class="col-md-2"> Type For </label>
                                            <div class="form-group col-md-3">
                                                <select class="form-control form-control-sm" [(ngModel)]="model.typefor"
                                                [ngModelOptions]="{standalone: true}">
                                                <option value="transfer">Transfer</option>
                                                <option value="point">Point</option>
                                            </select>
                                            </div>
                                            <div class = "col-md-6"></div>
                                            
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Account Number </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"  id = "idaccno"
                                                    [(ngModel)]="model.account_no"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                            <div class = "col-md-1"></div>
                                            <label class="col-md-2"> Opening Balance </label>
                                            <div class="form-group col-md-3">
                                                <input id = "opendingbalance" type = "number" class="form-control form-control-sm"
                                                    [(ngModel)]="model.balance"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>



                                        <div class="row">

                                            <label class="col-md-2"> Account Name </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm" id = "idaccname"
                                                    [(ngModel)]="model.account_name"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                            <div class = "col-md-1"></div>
                                            <label class="col-md-2"> Limited Amount </label>
                                            <div class="form-group col-md-3">
                                                <input type = "number" class="form-control form-control-sm"
                                                    [(ngModel)]="model.day_maxLimit"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                            
                                        </div>

                                     

                                        
                                  
                                  <!--XXXXXXXXXXXXXXXXXXXXX-->
                                <div class="row" >
                                    <label class="col-md-2"> Account Holder (Admin) </label>
                                    
                                    <div class="form-group col-md-3" id = "insertAdmin">
                                        <ngx-bootstrap-multiselect [options]="myOptions" [(ngModel)]="optionsModel" 
                                        [ngModelOptions]="{standalone: true}" class = "form-control-sm"  title  = "Add New Admin" style="margin-left : -7px"
                                        >
                                    </ngx-bootstrap-multiselect>
                                    </div>
                                   
                                 

                                    <div class = "col-md-1"></div>
                                    <label class="col-md-2"> Today-Imcome </label>
                                    <div class="form-group col-md-3">
                                        <input  type = "number" class="form-control form-control-sm"
                                            [(ngModel)]="model.today_reach_amt"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <!-------- XXXXX-->
                                
                                
                                        <div class="row">
                                            <label class="col-md-2"> Status </label>
                                            <div class="form-group col-md-3 radio">
                                                <div class="form-group clearfix">
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" checked id="radioSuccess1"
                                                            value="ACTIVE" [(ngModel)]="model.status"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess1">Active &nbsp;&nbsp;
                                                        </label>
                                                    </div>
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" id="radioSuccess2"
                                                            value="INACTIVE" [(ngModel)]="model.status"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess2">Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class = "col-md-1"></div>
                                            <label class="col-md-2">Is Show qr</label>
                                            <div class="form-group col-md-3 radio">
                                                <div class="form-group clearfix">
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r4"  id="radioSuccessType1"
                                                            value="true" [(ngModel)]="model.isshow_qr"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccessType1">Show &nbsp;&nbsp;
                                                        </label>
                                                    </div>
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r4" checked id="radioSuccessType2"
                                                            value="false" [(ngModel)]="model.isshow_qr"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccessType2">Not Show
                                                        </label>
                                                    </div>
                                                   
                                                </div>                                           
                                                <qrcode [qrdata]="model.topup_qrcode" [width]="160" [errorCorrectionLevel]="'M'" *ngIf="model.isshow_qr=='true'" ></qrcode>
                                                <input #file2 type="file" (change)="onSelects2(file2.files)" [accept]="'.jpg, .png, .gif, .jpeg'"
                                                 class="btn btn-file my-2" *ngIf="model.isshow_qr=='true' " />
                                                <br />
                                               

                                                <!-- <div class="qr-image-view" *ngIf="qrCodeResult2.length > 0 || isShowImage==true">
                                                    <img id="qr-image" alt="" style="max-width: 100%" />
                                                </div> -->

                                                <!-- <div class="icheck-success d-inline">
                                                    <input type="radio" name="r4" id="radioSuccessType1"
                                                        value="true" [(ngModel)]="model.isShow_qr"
                                                        [ngModelOptions]="{standalone: true}">
                                                    <label for="radioSuccessType1">Show &nbsp;&nbsp;
                                                    </label>
                                                </div>
                                                <div class="icheck-success d-inline">
                                                    <input type="radio" name="r4" checked id="radioSuccessType2"
                                                        value="false" [(ngModel)]="model.isShow_qr"
                                                        [ngModelOptions]="{standalone: true}">
                                                    <label for="radioSuccessType2">Not Show
                                                    </label>
                                                </div> -->
                                            </div>
                                        </div>

                                    
                                        
                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="save()">Update</button>&nbsp;</div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/payment-info']"> Cancel </button></div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div class="row" id="updatedata" style = "display: none;">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <form>
                                        <div class="row" style="margin-top: 2%;">
                                            <label class="col-md-2"> Created Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="model.createdDate"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Created By</label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="model.createdBy"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <label class="col-md-2"> Updated Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="model.updatedDate"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Updated By </label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="model.updatedBy"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
