import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  isOwnerLoggedIn: boolean=false;
  token: String="";
  currentRoute: string;
  constructor(
    private router: Router, 
    ){
   
     
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.isOwnerLoggedIn = JSON.parse(localStorage.getItem('isOwnerLoggedIn'));
    this.token = localStorage.getItem("token_en"); 
    if(this.isOwnerLoggedIn && this.token != null && this.token != undefined){    
        
      return true;
    }
    else{
      this.isOwnerLoggedIn = false;                 
      //localStorage.setItem('isOwnerLoggedIn', this.isOwnerLoggedIn);
      // localStorage.removeItem("token_en");      
      localStorage.clear();        
      this.router.navigate(['/login'],{replaceUrl: true});
      return false;
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
