import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  constructor(public spinner: NgxSpinnerService,
    public toastr: ToastrService,) { 

  }
  handleError(error){ 
    //console.log(JSON.stringify(error))
    if(error.status == 401){
      this.spinner.hide();
      this.toastr.error("", "Unauthorize User", {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
      return; 
    }   
    if(error.status == 423)
    {
      this.spinner.hide();
      //$("#deleteData").modal("show");
    }   
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
      return;  
    }
    
  }
}
