import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


import { FunctService } from '../../service/funct.service';

import { NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeSelectedFiles } from 'ngx-scanner-qrcode';
import { ApiTokenService } from '../../service/api-token.service';
declare var $: any;

@Component({
  selector: 'app-payment-detail-info',
  templateUrl: './payment-detail-info.component.html',
  styleUrls: ['./payment-detail-info.component.css']
})
export class PaymentDetailInfoComponent implements OnInit {


  model: any = {
    country_id: 0, bankType_id: 0, bankType_name: '', account_no: '', account_name: '', status: 'ACTIVE', today_reach_amt: 0,
    order_id: 0, day_maxLimit: 1000000, remark: '', balance: 0, handle_admin: '',
    isshow_qr: 'false', topup_qrcode: '',typefor:'transfer'
  };





  paymentList: any;
  CountryList = [];
  adminList = [];
  myOptions: IMultiSelectOption[];
  myUpdOptions: IMultiSelectOption[];
  optionsModel: number[];


  public qrCodeResult2: ScannerQRCodeSelectedFiles[] = [];
  @ViewChild('file2') qrFileElement: any;
  public config: ScannerQRCodeConfig = {
    // fps: 100,
    // isBeep: false,
    // decode: 'macintosh',
    // deviceActive: 1,
    constraints: {
      audio: false,
      video: {
        width: window.innerWidth
      }
    }
  };
  constructor(
    private apiTokenServer: ApiTokenService,
    private qrcode: NgxScannerQrcodeService,
    private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private router: Router,
    private route: ActivatedRoute, private funct: FunctService) {

  }


  public onSelects2(files: any): void {
    console.log(files)
    this.qrcode.loadFilesToScan(files, this.config).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
      console.log(res);
      if (res[0]?.data.length != 0) {
        console.log(res[0].data[0].value)
        console.log(res[0].data[0].value.includes('BTEwMC4w'))
        if (res[0].data[0].value.includes('BTEwMC4w')) {
          this.qrCodeResult2 = res;

          //this.onQRValueEmit.emit({qrValue:res[0].data[0].value,localfile:files[0]});
          this.model.topup_qrcode = res[0].data[0].value;
          // this.qrValue = res[0].data[0].value;
          // console.log(res[0].data[0].value)
          setTimeout(() => {
            (document.getElementById('qr-image') as HTMLImageElement).src = URL.createObjectURL(files[0])
          }, 100);
        } else {
          // this.qrFileElement.nativeElement.value = "";
          this.toastr.error("", 'Your QR Code is something wrong!', {
            timeOut: 3000,
            positionClass: 'toast-top-center',
          });
          return;
        }

      } else {
        // this.qrFileElement.nativeElement.value = "";
        this.toastr.error("", 'Your QR Code is something wrong!', {
          timeOut: 3000,
          positionClass: 'toast-top-center',
        });
        return;
      }
    });
  }

  isShowImage: boolean = false;
  addImage(file) {
    console.log(file)
    this.isShowImage = true;
    console.log(document.getElementById('qr-image'))
    setTimeout(() => {
      (document.getElementById('qr-image') as HTMLImageElement).src = URL.createObjectURL(file)
    }, 200);

  }
  async ngOnInit(): Promise<void> {
    this.spinner.show();

    await this.getAllCountry();
    await this.getAllAdmins();
    this.spinner.hide();

    // if (this.paymentdetailId == null) {
    //   $(document).ready(function () {
    //     $('#updatedata').hide();
    //     $("#updateAdmin").hide();
    //   });

    //   this.model = {
    //     id: 0,
    //     account_no: "",
    //     order_id : 0,
    //     type: '',
    //     status: 'ACTIVE',
    //     isshow_qr: 'false',
    //     isfor_Topup:'true',
    //     topup_qrcode: null,
    //     topup_file:'',
    //     image: '',
    //     createdDate: '',
    //     createdBy: '',
    //     updatedDate: '',
    //     updatedBy: '',
    //     min_amount:0,
    //    max_amount:0,
    //    maxAccountCount:0,
    //   limited_amt :0,
    //   today_reach_amt:0,
    //   handle_admin:'',
    //   balance : 0
    //   };
    // }
    // else {
    //   $(document).ready(function () {
    //       $("#insertAdmin").hide();
    //       $("#updateAdmin").hide();
    //   });
    //   this.model = {bankType_id:0,account_no:'',account_name:'',status:'',
    //   order_id:0,day_maxLimit:0,isfor_Topup:false,remark:'',balance:0,
    //   isshow_qr:false,topup_qrcode:''};
    // }
    //this.getPaymentById();
    $('#updatedata').hide();
    $("#updateAdmin").hide();
  }

  getAllAdmins() {
    let data = localStorage.getItem('adminList');
    if (data != undefined && data != null && data != 'undefined') {
      this.adminList = JSON.parse(data);
      this.myOptions = this.adminList;
    }
    this.adminList = [];

    this.apiTokenServer.post(this.funct.ipaddress + 'authenticate/list', JSON.stringify(""), true, true).then(
      response => {
        if (response.data.data != null) {
          this.adminList = JSON.parse(response.data.data);
          this.myOptions = this.adminList;
          localStorage.setItem('adminList', JSON.stringify(this.adminList));
        }
      }
    );
  }


  async getAllCountry() {
    this.CountryList = [];
    await this.apiTokenServer.get(`${this.funct.ipaddress}country/getallcountry`, true)
      .then(response => {

        if (response.data.data != null) {
          let data = JSON.parse(response.data.data);
          this.model.country_id=data[0].id;
          this.getBankTypeList();
          //console.log(data)
          var newResult = [];
          // newResult.push({id:0,text: 'Select Country'});   
          data.forEach(arr => {
            let text = arr.code;
            newResult.push({ ...arr, ...{ text: text } });
          });
          this.CountryList = newResult;
        } else {
          this.CountryList = [];
        }

        this.spinner.hide();
      })
      .catch(error => {
        this.toastr.error("Limited Access.", 'Forbidden!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });

      });

  }

  fromCountry() {
    this.model.country_id = $("#country_id").val();
    if (this.model.country_id != 0) {
      this.getBankTypeList();
    }

  }

  async getBankTypeList() {
    let dataList = localStorage.getItem("banktypeListByCountry");
    if (dataList != undefined && dataList != null) {
      this.paymentList = JSON.parse(dataList);
    }
    let model = JSON.stringify(this.model.country_id);
    await this.apiTokenServer.post(this.funct.ipaddress + 'banktype/getbankTypeByCountry', model, true, true)
      .then(response => {
        this.paymentList = response.data;
        localStorage.setItem("paymentList", JSON.stringify(this.paymentList));
      });

  }





  async save() {


    if (this.model.bankType_id == "" || this.model.bankType_id == undefined || this.model.bankType_id == null || this.model.bankType_id == 0) {
      this.toastr.error("Please choose bank type", '', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }

    if (this.model.isshow_qr == 'true') {
      if (this.model.topup_qrcode == "" || this.model.topup_qrcode == undefined || this.model.topup_qrcode == null) {
        this.toastr.error("Please upload  QR", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        return;
      }

    }


    if (this.model.account_no == "" || this.model.account_no == undefined || this.model.account_no == null) {
      this.toastr.error("Please enter account no", '', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }

    if (this.model.account_name == "" || this.model.account_name == undefined || this.model.account_name == null) {
      this.toastr.error("Please enter account name", '', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    let mobNumberPattern = /[0-9]/g;
    if (!mobNumberPattern.test(this.model.account_no)) {
      this.toastr.error("Account number must be number", '', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }

    if (this.model.limited_amt == 0) {
      this.toastr.error("Limited amount must not be zero", '', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    if (this.optionsModel.length > 0) {
      this.model.handle_admin = this.optionsModel.toString();
    }
    if (this.model.handle_admin.length == 0 || this.model.handle_admin == "" || this.model.handle_admin == undefined || this.model.handle_admin == null) {
      this.toastr.error("Please choose account holder (Admin)", '', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    this.spinner.show();


    this.model.handle_admin = this.optionsModel.toString();
    let dataTosend = JSON.stringify(this.model);
    await this.apiTokenServer.post(this.funct.ipaddress + 'bankaccount/Add', dataTosend, true, true).then(
      response => {
        if (response.data.statusCode == 200) {
          this.spinner.hide();
          this.router.navigate(['/payment-info']).then(() => {
            this.toastr.success(response.data.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        } else {
          this.spinner.hide();
          this.toastr.error(response.data.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
        }
      }
    );
    this.spinner.hide();

    // if( this.model.payment_id == "" || this.model.payment_id  == undefined ||  this.model.payment_id  == null)
    // {
    //    this.toastr.error("Please choose bank type", 'Invalid!', {
    //      timeOut: 3000,
    //      positionClass: 'toast-top-right',
    //    });
    //    return;
    // }
    // if(this.bankName == 'KBZ Pay' ){
    //   if(this.model.isshow_qr == 'Show'){
    //     if( this.model.topup_qrcode == "" || this.model.topup_qrcode  == undefined ||  this.model.topup_qrcode  == null){
    //       this.toastr.error("Please upload KBZ QR", 'Invalid!', {
    //         timeOut: 3000,
    //         positionClass: 'toast-top-right',
    //       });
    //       return;
    //     }

    //   }

    // }
    //   // if(this.bankName == 'KBZ Pay'){
    //   //   if( this.model.topup_qrcode == "" || this.model.topup_qrcode  == undefined ||  this.model.topup_qrcode  == null){
    //   //     this.toastr.error("Please upload KBZ QR", 'Invalid!', {
    //   //       timeOut: 3000,
    //   //       positionClass: 'toast-top-right',
    //   //     });
    //   //     return;
    //   //   }
    //   // }
    //        if( this.model.account_no == "" || this.model.account_no  == undefined ||  this.model.account_no  == null)
    //        {
    //           this.toastr.error("Please enter account no", 'Invalid!', {
    //             timeOut: 3000,
    //             positionClass: 'toast-top-right',
    //           });
    //           return;
    //        }

    //        if (!mobNumberPattern.test(this.model.account_no))
    //        {
    //          this.toastr.error("Account number must be number", 'Invalid!', {
    //            timeOut: 3000,
    //            positionClass: 'toast-top-right',
    //          });
    //          return;
    //        }
    //    if(this.model.max_amount < this.model.min_amount)
    //        {
    //           this.toastr.error("Maximum amount must be greather than minimum amount", 'Invalid!', {
    //             timeOut: 3000,
    //             positionClass: 'toast-top-right',
    //           });
    //           return;
    //        }
    //        if(this.model.limited_amt == 0)
    //        {
    //           this.toastr.error("Limited amount must not be zero", 'Invalid!', {
    //             timeOut: 3000,
    //             positionClass: 'toast-top-right',
    //           });
    //           return;
    //        }
    //        if(this.optionsModel.length > 0)
    //        {
    //         this.model.handle_admin = this.optionsModel.toString();
    //        }
    //       if( this.model.handle_admin.length == 0 || this.model.handle_admin == "" || this.model.handle_admin  == undefined ||  this.model.handle_admin  == null)
    //        {
    //          this.toastr.error("Please choose account holder (Admin)", 'Invalid!', {
    //            timeOut: 3000,
    //            positionClass: 'toast-top-right',
    //          });
    //          return;
    //        }
    //   this.spinner.show();
    // if (mobNumberPattern.test(this.model.account_no)) {
    //       this.token = this.storage.retrieve('token');
    //       let headers = new HttpHeaders();
    //       headers = headers.set('Authorization', this.token);
    //       this.http.post(this.funct.ipaddress + 'payment/insertPaymentDetail', this.model, { headers: headers })
    //       .pipe(
    //         catchError(this.handleError.bind(this))
    //        )
    //       .subscribe( 
    //         result => {
    //           this.dto.Response = {};
    //           this.dto.Response = result;
    //           if (this.dto.Response.status == 'Success') {
    //             this.spinner.hide();
    //             this.router.navigate(['/payment-info']).then(() => {
    //               this.toastr.success(this.dto.Response.message, 'Success!', {
    //                 timeOut: 3000,
    //                 positionClass: 'toast-top-right'
    //               });
    //             })
    //           }
    //           else {
    //             this.spinner.hide();
    //             this.toastr.error(this.dto.Response.message, 'Invalid!', {
    //               timeOut: 3000,
    //               positionClass: 'toast-top-right',
    //             });
    //           }
    //         });
    //   }
    // else {
    //   this.spinner.hide();
    //   this.toastr.error('Please enter valid account no.', 'Invalid input!', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //   });
    // }
  }

  edit() {

    //   this.token = this.storage.retrieve('token');
    //   let headers = new HttpHeaders();
    //   headers = headers.set('Authorization',  this.token);
    //   this.model.payment_id = this.paymentId;
    //  // let mobNumberPattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im;
    //  let mobNumberPattern =/[0-9]/g;
    //   if( this.model.payment_id == "" || this.model.payment_id  == undefined ||  this.model.payment_id  == null)
    //   {
    //      this.toastr.error("Please choose bank type", 'Invalid!', {
    //        timeOut: 3000,
    //        positionClass: 'toast-top-right',
    //      });
    //      return;
    //   }

    //   if(this.bankName == 'KBZ Pay'){
    //     if(this.model.isshow_qr == 'Show'){
    //       if( this.model.topup_qrcode == "" || this.model.topup_qrcode  == undefined ||  this.model.topup_qrcode  == null){
    //         this.toastr.error("Please upload KBZ QR", 'Invalid!', {
    //           timeOut: 3000,
    //           positionClass: 'toast-top-right',
    //         });
    //         return;
    //       }
    //     }

    //   }

    //   if( this.model.account_no == "" || this.model.account_no  == undefined ||  this.model.account_no  == null)
    //   {
    //      this.toastr.error("Please enter account no", 'Invalid!', {
    //        timeOut: 3000,
    //        positionClass: 'toast-top-right',
    //      });
    //      return;
    //   }
    //  if(this.model.max_amount < this.model.min_amount)
    //   {
    //      this.toastr.error("Maximum amount must be greather than minimum amount", 'Invalid!', {
    //        timeOut: 3000,
    //        positionClass: 'toast-top-right',
    //      });
    //      return;
    //   }

    //   if(this.model.limited_amt == 0)
    //   {
    //      this.toastr.error("Limited amount must not be zero", 'Invalid!', {
    //        timeOut: 3000,
    //        positionClass: 'toast-top-right',
    //      });
    //      return;
    //   }
    //     if(this.optionsModel.length > 0)
    //     {
    //      this.model.handle_admin = this.optionsModel.toString();
    //     }
    //   this.spinner.show();
    //   this.http.post(this.funct.ipaddress + 'payment/updatePaymentDetail', this.model, { headers: headers }).subscribe( 
    //     result => {
    //       this.dto.Response = {};
    //       this.dto.Response = result;
    //       if (this.dto.Response.status == 'Success') {
    //         this.spinner.hide();
    //         this.router.navigate(['/payment-info']).then(() => {
    //           this.toastr.success(this.dto.Response.message, 'Success!', {
    //             timeOut: 3000,
    //             positionClass: 'toast-top-right'
    //           });
    //         })
    //       }
    //       else {
    //         this.spinner.hide();
    //         this.toastr.error(this.dto.Response.message, 'Invalid!', {
    //           timeOut: 3000,
    //           positionClass: 'toast-top-right',
    //         });
    //       }
    //     });
  }


  changeBankType(id, bankName) {
    this.model.bankType_id = id;
    this.model.bankType_name = bankName;
  }


  flagAdmin() {
    this.model.handle_admin = $("#admin_id").val();
  }

  goModal(event) {
    //  this.cashIdid = event.target.id;
    // $('#deleteCash').modal("show");
  }

  deleteOk() {
    this.spinner.show();
    // this.token = this.storage.retrieve('token');
    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization',  this.token);
    // let formData= new FormData();
    // formData.append("id",this.cashIdid);
    // this.http.post(this.funct.ipaddress + 'payment/deletCashIn', formData, {headers: headers})
    // .pipe(
    //   catchError(this.handleError.bind(this))
    //  )
    // .subscribe(
    //   result => {
    //     this.dto.Response = {};
    //     this.dto.Response = result;
    //     if (this.dto.Response.status == 'Success') {
    //       this.spinner.hide();
    //       $('#deleteCash').modal("hide");
    //       window.location.href = window.location.href;

    //       this.router.navigate(['/payment-info-detail', this.paymentdetailId]).then(() => {
    //         this.toastr.success(this.dto.Response.message, 'Success!', {
    //           timeOut: 3000,
    //           positionClass: 'toast-top-right'
    //         });

    //       })
    //     }
    //     else {
    //       this.spinner.hide();
    //       this.toastr.error(this.dto.Response.message, 'Invalid!', {
    //         timeOut: 3000,
    //         positionClass: 'toast-top-right',
    //       });
    //     }
    //   });
  }
  hideModal() {
    $('#deleteCash').modal("hide");
  }
  public onQRValueEmit(qrValue: any): void {
    this.model.topup_qrcode = qrValue.qrValue;
    this.model.topup_file = qrValue.localfile;

  }

  ngAfterViewInit() {
    setTimeout(() => {
      // this.renderWidgetInsideWidgetContainer();
    }, 0);
  }

}
