<div class="row">
    <div class="col-sm-12 col-md-5">
       <div *ngIf="rowsFound>0" class="dataTables_info" id="tblTopup2_info" role="status" aria-live="polite">Showing {{(pageIndex-1)*pageLength+1}} to {{currentPageEndRow}} of {{rowsFound}} entries</div>
    </div>
    <div class="col-sm-12 col-md-7">
       <div class="dataTables_paginate paging_simple_numbers" id="tblTopup2_paginate">
          <ul class="pagination">
             <li (click)="previoud()" [ngClass]="hasPrevious?'paginate_button page-item previous':'paginate_button page-item previous disabled'"  id="tblTopup2_previous"><a  aria-controls="tblTopup2" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
           
           
             <li *ngFor="let item of totalPage" [ngClass]="item == pageIndex ? 'paginate_button page-item  active': 'paginate_button page-item '"
             (click)="goPage(item)"
             >
                <a   data-dt-idx="{{item}}" tabindex="0" class="page-link">{{item}}</a>
            </li>            
            <li    (click)="next()" [ngClass]="hasNext?'paginate_button page-item next':'paginate_button page-item next disabled'" id="tblTopup2_next"><a  aria-controls="tblTopup2" data-dt-idx="8" tabindex="0" class="page-link">Next</a></li> 
          </ul>
       </div>
    </div>
 </div>