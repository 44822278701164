export const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsrsdg2Xijd+Ak7f+e106
6OD3MOi0nGGDeEcY/T82ATUi3pnAnMnwD0hsHltNLYtvriHLPNAiHIaeYWyT+zqv
qoyji0erJ/BN5VGEuHuAbIOs7fJ/akk/qrvYF1oskIseWqlZXHjJQp+d0DUaHshr
xudVBkBCm7H4LM7YWs449J2fA0SmwaEwxIHJnsmuux780AsvCxIgRGpt7Sjh4Y4/
af98pqiXgBn9qseiR4N7xhKz/LYjk1KQCzDg+6aCeLwEmqt7Q697aAxJnxukZnDi
NYj7U0wtbRM9UVk0vtmlkXRXhO8z4nYedN+I3E2uyw7dwz2HqWVDsX2ltNIb94oj
bQIDAQAB
-----END PUBLIC KEY-----`;


