<div class="dataTables_wrapper">
<div class="row">
   <div class="col-sm-12 col-md-6">
      <div class="dataTables_length" id="tblTwoD2_length">
         <label>
            Show 
            <select  (change)="change($event.target.value)" name="tblTwoD2_length" aria-controls="tblTwoD2" class="custom-select custom-select-sm form-control form-control-sm">
              
               <option  value="{{item.key}}" *ngFor="let item of pages" [selected]="item.key == pageLength">{{item.key}}</option>              
            </select>
            entries
         </label>
      </div>
   </div>
   <div class="col-sm-12 col-md-6">
      <div id="tblTwoD2_filter" class="dataTables_filter">
         <label>Search:
            <input type="search" class="form-control form-control-sm" placeholder="" aria-controls="tblTwoD2" (input)="onSearchChange($event.target.value)">
         </label>
      </div>
   </div>
</div>
</div>