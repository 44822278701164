import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatePickerModule } from '@ngx-tiny/date-picker';
import { NgxPaginationModule } from 'ngx-pagination';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatDialogModule} from '@angular/material/dialog'; 
import { MatButtonModule } from '@angular/material/button';
import { QRCodeModule } from 'angularx-qrcode';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { 
  HeaderSidebarComponent,
  FooterComponent,
  PaginHeaderComponent,
  PaginFooterComponent,
  SortingComponent
 } from './components/index';
import { NgSelect2Module } from 'ng-select2';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
const com = [
  HeaderSidebarComponent,
  FooterComponent,  
  PaginHeaderComponent,
  PaginFooterComponent,
  SortingComponent,
];


@NgModule({
  declarations: [
    com, 
  ],
  imports: [ 
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DataTablesModule,
    NgxDatePickerModule,
    NgxPaginationModule,  
    BsDatepickerModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    NgxBootstrapMultiselectModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularEditorModule,
    MatDialogModule,
    MatButtonModule,
    NgSelect2Module,
    QRCodeModule,
    ModalModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  exports: [
    com,
    RouterModule,
    ReactiveFormsModule,
  ],
  providers: [    
    BsModalService,
  ],
   
  
})
export class SharedModule { }
