import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FunctService } from '../../service/funct.service';
import { ApiTokenService } from '../../service/api-token.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { truncateSync } from 'fs';
import { FilterService } from '@baseUrl/src/app/service/filter.services';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-payment-info',
  templateUrl: './payment_info.component.html',
  styleUrls: ['./payment_info.component.css']
})
export class PaymentInfoComponent implements OnInit {
  bankTypeList: any;
  MyModel: any = { from_country_id: 0, account_no: '',typefor: '', status: '', bankType_id: '', pageNumber: 1, pageSize: 20, rowsfound: 0, };
  resultlist: any[];
  originalList: any[] = [];
  private searchTermSubscription: Subscription;
  paymentBankAccountHolderDetaiList: any;
  modalRef: BsModalRef;
  CountryList = []

  constructor(
    private apiTokenServer: ApiTokenService,
    private spinner: NgxSpinnerService, private modalService: BsModalService,
    private router: Router, private funct: FunctService, private toastr: ToastrService,
    private filterService: FilterService,) {

    this.getAllCountry();
  }

  ngOnInit(): void {
    this.search();
    this.MyModel = { account_no: '', type: '', typefor: '', status: '', bankType_id: '', pageNumber: 1, pageSize: 20 };
    this.resultlist = this.MyModel;

    this.searchTermSubscription = this.filterService.currentSearchTerm.subscribe(searchTerm => {
      this.filterData(searchTerm);
    });
  }

  async getAllCountry() {
    this.CountryList = [];
    await this.apiTokenServer.get(`${this.funct.ipaddress}country/getallcountry`, true)
      .then(response => {

        if (response.data.data != null) {
          let data = JSON.parse(response.data.data);
          //console.log(data)
          var newResult = [];
          newResult.push({ id: 0, text: 'Select Country' });
          data.forEach(arr => {
            let text = arr.name;
            newResult.push({ ...arr, ...{ text: text } });
          });
          this.CountryList = newResult;
        } else {
          this.CountryList = [];
        }

        this.spinner.hide();
      })
      .catch(error => {
        this.toastr.error("Limited Access.", 'Forbidden!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });

      });

  }

  fromCountry() {
    this.MyModel.from_country_id = $("#from_country_id").val();
    if (this.MyModel.from_country_id != 0) {
      this.getBankTypeList();
    }

  }

  async getBankTypeList() {
    let dataList = localStorage.getItem("banktypeListByCountry");
    if (dataList != undefined && dataList != null) {
      this.bankTypeList = JSON.parse(dataList);
    }
    let model = JSON.stringify(this.MyModel.from_country_id);
    await this.apiTokenServer.post(this.funct.ipaddress + 'banktype/getbankTypeByCountry', model, true, true)
      .then(response => {
        this.bankTypeList = response.data;
        localStorage.setItem("banktypeList", JSON.stringify(this.bankTypeList));
      });

  }
  changePage(val: number) {
    this.MyModel.pageNumber = val;
    this.search();
  }
  changePageSize(val: number) {
    this.MyModel.pageSize = val;
    this.search();

  }

  ngOnDestroy() {
    this.searchTermSubscription.unsubscribe();
  }

  async search() {
    this.spinner.show();
    let dataToSend = JSON.stringify(this.MyModel);

    console.log(dataToSend)

    await this.apiTokenServer.post(this.funct.ipaddress + 'bankaccount/List', dataToSend, true, true)
      .then(response => {
        if (response.data.statusCode === 200) {
          this.spinner.hide();

          let data = JSON.parse(response.data.data);
          this.resultlist = data.results;
          this.originalList = data.results;
          this.MyModel.rowsfound = data.totalRows;
          // this.router.navigate(['/admin-withdraw-list']).then(() => {
          //   this.toastr.success(response.data.message, 'Success!', {
          //     timeOut: 3000,
          //     positionClass: 'toast-top-right'
          //   });
          // });
        } else {
          this.spinner.hide();
          this.showErrpr(response.data.message);
        }
      });
    this.spinner.hide();

  }

  showErrpr(msg) {
    this.toastr.error(msg, '', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
    });
    this.spinner.hide();
  }


  DetailModalDialog(template: TemplateRef<any>, id, accountNo, bankName, accountName) {
    let title = "Account Holder (Admin) Detail";
    let api = "bankaccount/GetBankAccountHandlerList?id=" + id;

    let initialState = { title: title };
    let modalConfig = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: true };
    this.apiTokenServer.getStr(`${this.funct.ipaddress}${api}`)
      .then(response => {
        if (response.status == 200) {


          this.spinner.hide();
          console.log(response.data)
          this.paymentBankAccountHolderDetaiList = response.data;
          this.modalRef = this.modalService.show(template, Object.assign({}, modalConfig, { class: 'modal-lg modal-dialog-centered', initialState }));
          return;
        }
      })
      .catch(error => {
        this.spinner.hide();
        this.toastr.error('Error', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      });


  }

  goModal(id, accountNo, bankName, codeNumber, accountName) {
    // var id1 = 'paymentBankAccountHolderDetaiList' + this.idIndex2;
    // var table = $('#' + id1).DataTable();
    // table.destroy();
    // this.idIndex2 = this.idIndex2 +1;
    // this.spinner.show();
    // this.token = this.storage.retrieve('token'); 
    // this.paymentBankAccountHolderDetaiList = [];   
    //  const headers= {'Authorization': this.token};
    //  const params = {'paymentDetailId': id};
    // axios.get(this.funct.ipaddress + 'payment/GetAccountHolderDetaiList', { params: params, headers: headers })
    // .then(response=> {
    //   this.dto.Response = {};
    //   this.dto.Response = response.data;
    //   this.paymentBankAccountHolderDetaiList = this.dto.Response;
    //   this.account_no = accountNo;
    //   this.bankName = bankName;
    //   this.code_number = codeNumber;
    //   this.account_name = accountName;
    //   console.log("AKP: " +JSON.stringify(this.paymentBankAccountHolderDetaiList));
    //   this.dtTrigger2.next();
    // })
    // .catch(error=>{
    //   this.errorMessage.handleError(error.response)    
    // });

    // this.spinner.hide();
    // $('#browseAccountHolderList').modal("show");
  }

  filterData(searchKey: string) {
    if (!searchKey) {
      this.resultlist = [...this.originalList];
    } else {
      this.resultlist = this.originalList.filter(item =>
        Object.values(item).some(val =>
          val && val.toString().toLowerCase().includes(searchKey)
        )
      );
    }
  }

}
