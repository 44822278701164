
<div *ngIf="!showHeader"  class="wrapper"  >
    <app-header-sidebar></app-header-sidebar>  
    <router-outlet></router-outlet>

    <app-floating-chat></app-floating-chat> 
    <router-outlet></router-outlet>
    <aside class="control-sidebar control-sidebar-dark">     
    </aside>
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
</div>
<router-outlet *ngIf="showHeader"></router-outlet>
