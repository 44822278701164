import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router,  } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FunctService } from '../../service/funct.service';
import { ApiTokenService } from '../../service/api-token.service';
import { FilterService } from '@baseUrl/src/app/service/filter.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-banktype',
  templateUrl: './banktype.component.html',
  styleUrls: ['./banktype.component.css']
})
export class BanktypeComponent implements OnInit {

  bankTypeList: any;
  resultlist:any[];
  originalList: any[] = [];
  private searchTermSubscription: Subscription;
  MyModel :any={name:'',status:'',minAcceptAmount:0,maxAcceptAmount:0,country_id:0
  ,pageNumber:1,pageSize:20,rowsfound:0,};
  CountryList = [];
  constructor( private apiTokenServer:ApiTokenService,
    private spinner: NgxSpinnerService,  private router: Router, private funct: FunctService,
     private toastr: ToastrService, private filterService: FilterService,) {

      this.getAllCountry();
      
      }


      async getAllCountry() {    
        this.CountryList = [];
        await this.apiTokenServer.get(`${this.funct.ipaddress}country/getallcountry`,true)
          .then(response => {
            
            if(response.data.data !=null){
              let data = JSON.parse(response.data.data);
              console.log(data)
              var newResult= [];
              newResult.push({id:0,text: 'Select Country'});   
              data.forEach(arr => {
                let text= arr.name;
                newResult.push({...arr,...{text: text}});          
              });
              this.CountryList = newResult;
            } else{         
              this.CountryList =[];         
            }       
          
            this.spinner.hide();
          })
          .catch(error => {        
              this.toastr.error("Limited Access.", 'Forbidden!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
            
          });
        
      }


      flagRole(){
        this.MyModel.country_id = $("#country_id").val();
      }

  ngOnInit(): void {
    this.search();
    this.MyModel = {name:'',status:'',minAcceptAmount:0,maxAcceptAmount:0,pageNumber:1,pageSize:20,rowsfound:0,};
    this.resultlist = this.MyModel;

    this.searchTermSubscription = this.filterService.currentSearchTerm.subscribe(searchTerm => {
      this.filterData(searchTerm);
    });
  }

  changePage(val: number) {
    this.MyModel.pageNumber=val;
    this.search();
  }
  changePageSize(val :number){
    this.MyModel.pageSize=val;
    this.search();  
  }

    async search() {

      this.resultlist = [];
    this.spinner.show();
   let dataToSend=JSON.stringify(this.MyModel);
    //console.log(dataToSend)
    await this.apiTokenServer.post(this.funct.ipaddress + 'banktype/GetAllBankTypeByParams', dataToSend,true,true)
    .then(response => {
      if (response.data.statusCode === 200) {
        this.spinner.hide();

        let data=JSON.parse(response.data.data);
        this.resultlist=data.results;
        this.originalList = data.results;
        this.MyModel.rowsfound=data.totalRows;
        // this.router.navigate(['/admin-withdraw-list']).then(() => {
        //   this.toastr.success(response.data.message, 'Success!', {
        //     timeOut: 3000,
        //     positionClass: 'toast-top-right'
        //   });
        // });
      } else {
      
        this.spinner.hide();
       this.showErrpr(response.data.message);
      }
    });
    this.spinner.hide();
    
  }

  ngOnDestroy() {
    this.searchTermSubscription.unsubscribe();
  }

  showErrpr(msg){
    this.toastr.error(msg, '', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
      });
      this.spinner.hide();
  }

  filterData(searchKey: string) {
    if (!searchKey) {
      this.resultlist = [...this.originalList]; 
    } else {
      this.resultlist = this.originalList.filter(item =>
        Object.values(item).some(val =>
          val && val.toString().toLowerCase().includes(searchKey)
        )
      );
    }
  }


}
