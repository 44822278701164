import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagin-footer',
  templateUrl: './pagin-footer.component.html',
  styleUrls: ['./pagin-footer.component.css']
})
export class PaginFooterComponent implements OnInit {
  @Input() rowsFound = 0;
  @Input() pageLength = 20;
  @Input() pageIndex = 1;
  @Output() changePage = new EventEmitter<number>();

  hasPrevious:boolean=false;
  totalPage:any=[];
  actualTotalPage=0;
  hasNext:boolean=false;
  currentPageEndRow:number=0;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
        
    this.checkPage();
}

  ngOnInit(): void {

    this.checkPage();
  }

  checkPage(){
    
    
    if(this.rowsFound>0){
        this.currentPageEndRow=this.pageIndex*this.pageLength;
        if(this.currentPageEndRow>this.rowsFound){
          this.currentPageEndRow=this.rowsFound;
        }
        this.actualTotalPage =Math.ceil(this.rowsFound/this.pageLength);
        this.hasPrevious=this.pageIndex>1;
        this.hasNext=this.pageIndex<this.actualTotalPage;
        this.totalPage=[];

        //maxshow page 10
        if(this.actualTotalPage>10){
          
            let endpageindex=this.pageIndex+5;
            let startpageindex=this.pageIndex-5;
  
            let startDiff=startpageindex;
            if(startDiff<0){
              startDiff=Math.abs(startDiff);
            }else{
              startDiff=0;
            }
            let endDiff=endpageindex;
            if(endDiff>this.actualTotalPage){
              endDiff=endDiff-this.actualTotalPage;
  
            }else{
              endDiff=0;
            }
  
           
            endpageindex=endpageindex>this.actualTotalPage?this.actualTotalPage:endpageindex;
            //endpageindex=endpageindex<10?10:endpageindex;
            startpageindex=startpageindex<1?1:startpageindex;
            for(let i=startpageindex-endDiff;i<=endpageindex+startDiff;i++){
              this.totalPage.push(i);
            }
          
  
        }else{//within 10 page show all
          for(let i=1;i<=this.actualTotalPage;i++){
            this.totalPage.push(i);
          }
        }        
    }
  }

  goPage(page){
    if(page<=this.actualTotalPage && (page>0)){
      this.pageIndex=page;
      this.changePage.emit(this.pageIndex);
    }
    this.checkPage();
  }
  next(){
    if(this.currentPageEndRow<this.rowsFound){
      this.pageIndex++;
      this.changePage.emit(this.pageIndex);
    }
    this.checkPage();
  }
  
  previoud(){
    if(this.pageIndex>0){
      this.pageIndex--;
      this.changePage.emit(this.pageIndex);
      
    }
    this.checkPage();

  }



}
