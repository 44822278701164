import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header-sidebar',
  templateUrl: './header-sidebar.component.html',
  styleUrls: ['./header-sidebar.component.css']
})
export class HeaderSidebarComponent implements OnInit {

  adminPhone: any;
  constructor(
    private toastr: ToastrService,
    private router: Router,) {
    var admindataexist = JSON.parse(localStorage.getItem('adminDTO'));
    if (admindataexist != undefined && admindataexist != null) {
      this.adminPhone = admindataexist.phone_no;
      // if (admindataexist.role_name != "SUPER_ADMIN") {
      //   this.sidebarMenu.splice(26, 1);          
      // }

      //   if (admindataexist.role_name == "ADMIN") {
      //     this.sidebarMenu.find(item => item.title === "BUSINESS").child = [{
      //         title: "Transfer",
      //         url: "/transfer",
      //         icon: "fa-hand-holding-usd",
      //         active: false
      //     }];
      // }  
      if (admindataexist.role_name == "TOPUP_ADMIN" || admindataexist.role_name == "WITHDRAW_ADMIN" || admindataexist.role_name == "TRAN_DELETE_ADMIN") {
        this.sidebarMenu = [
          { id: 7, title: "BUSINESS", header: true, url: "", icon: "", active: false, menu: false, child: [] },
          {
            id: 8, title: "Transaction", url: "#", icon: "fa-comments-dollar", active: false, menu: false, child: [
              { title: "Transfer", url: "/transfer", icon: "fa-hand-holding-usd", active: false }
            ]
          }
        ];
      }
    }

    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        console.log(val.url)
        let isOwnerLoggedIn = JSON.parse(localStorage.getItem('isOwnerLoggedIn'));
        if (isOwnerLoggedIn && val.url == "/") {
          this.router.navigate(['/dashboard'], { replaceUrl: true });

        }
        let sMenu = this.sidebarMenu.find(x => x.url == val.url);
        if (sMenu != null && sMenu != undefined) {
          sMenu.active = true;
        }
        else {
          for (let i = 0; i < this.sidebarMenu.length; i++) {
            var child = this.sidebarMenu[i].child;
            let childUrl = child.find(x => x.url == val.url);
            if (childUrl != null && childUrl != undefined) {
              childUrl.active = true;
              this.sidebarMenu[i].menu = true;
              this.sidebarMenu[i].active = true;
              return;
            }
          }
        }
      }
    });
  }

  sidebarMenu: any = [
    { id: 1, title: "Dashboard", header: false, url: "/dashboard", icon: "fa-tachometer-alt", active: false, menu: false, child: [] },
    { id: 2, title: "MANAGEMENT", header: true, url: "", icon: "", active: false, menu: false, child: [] },
    { id: 3, title: "Admin", url: "/admin", icon: "fa-user-shield", active: false, menu: false, child: [] },

    {
      id: 4, title: "Users", url: "#", icon: "fa-users", active: false, menu: false, child: [
        { title: "User", url: "/user", icon: "fa fa-user", active: false },
        { title: "User Level", url: "/level", icon: "fa-layer-group", active: false },
        { title: "User Level List", url: "/usrlevel", icon: "fa fa-bars", active: false },

      ]
    },
    {
      id: 5, title: "Agents", url: "#", icon: " fa-user-tie", active: false, menu: false, child: [
        { title: "Agent", url: "/agent", icon: "fa fa-user", active: false },
        { title: "Agent Level", url: "/agent-level", icon: "fa-comments-dollar", active: false },
        { title: "Agent Commession", url: "agent/commession", icon: "fa fa-calculator", active: false },
        { title: "Commession Pay", url: "agent/commession-pay", icon: "fa fa-coins", active: false },

      ]
    },
    { id: 6, title: "Country", url: "/country", icon: "fa-flag", active: false, menu: false, child: [] },

    {
      id: 8, title: "Bank Information", url: "#", icon: "fa fa-landmark", active: false, menu: false, child: [
        { title: "Bank Type", url: "/banktype", icon: "fa fa-wallet", active: false },
        { title: "Bank Account", url: "/payment-info", icon: "fa fa-money-check", active: false },
      ]
    },
    { id: 9, title: "BUSINESS", header: true, url: "", icon: "", active: false, menu: false, child: [] },
    {
      id: 10, title: "Transaction", url: "#", icon: "fa-comments-dollar", active: false, menu: false, child: [

        { title: "Transfer", url: "/transfer", icon: "fa-hand-holding-usd", active: false },
        { title: "Points Purchase", url: "/point/point-tran", icon: "fas fa-file-invoice", active: false },
        { title: "Exchange Rate", url: "/exchange", icon: "fa-file-invoice-dollar", active: false }

      ]
    },
    {
      id: 11, title: "Reporting", url: "#", icon: "fa-file", active: false, menu: false, child: [
        { title: "Financial Report", url: "/report/financialreport", icon: "fa-file", active: false },
        { title: "User Transfer List", url: "/transfer/user-transfer-list", icon: "fa-list-alt", active: false },
        { title: "User Registration List", url: "/report/user-registration-report", icon: "fa-file", active: false },

      ]
    },
    { id: 12, title: "INFORMATION", header: true, url: "", icon: "", active: false, menu: false, child: [] },
    { id: 13, title: "Ads", url: "/ads", icon: "fa-ad", active: false, menu: false, child: [] },
    { id: 14, title: "Template", url: "/template", icon: "fa-newspaper", active: false, menu: false, child: [] },
    //{ id: 15, title: "Prmotion", url: "/promotion", icon: "fa-gift", active: false, menu: false, child: [] },
    { id: 16, title: "Prmotion-Ads", url: "/promotion-ads", icon: "fa-gift", active: false, menu: false, child: [] },
    {
      id: 17, title: "Point", url: "#", icon: "fas fa-coins", active: false, menu: false, child: [
        { title: "Point Setting", url: "/point/point-setting", icon: "fas fa-cogs", active: false },
        { title: "Point Config", url: "/point/point-config-detail", icon: "far fa-check-circle", active: false },
        { title: "Point Price", url: "/point/point-price", icon: "fas fa-donate", active: false },
      ]
    },
    {
      id: 18, title: "Tutorial", url: "#", icon: "fas fa-question-circle", active: false, menu: false, child: [
        { title: "Tutorial Sample", url: "/tutorial", icon: "fa-info-circle", active: false },
        { title: "Tutorial Vedio", url: "/tutorial/vedio-list", icon: "far fa-check-circle", active: false },
      ]
    },

    { id: 19, title: "Notification", url: "/notification", icon: "fa-bell", active: false, menu: false, child: [] },
    { id: 20, title: "Change Password", url: "/changepassword-list", icon: "fa-edit", active: false, menu: false, child: [] },


  ];

  ngOnInit(): void {

  }


  goToRoute(id, url) {
    if (url == "#") {
      let menu = this.sidebarMenu.find(x => x.id == id).menu;
      this.sidebarMenu.find(x => x.id == id).menu = !menu;
    }
    else {
      for (let i = 0; i < this.sidebarMenu.length; i++) {
        var child = this.sidebarMenu[i].child;
        if (child.length > 0) {
          for (let j = 0; j < child.length; j++) {
            child[j].active = false;
          }
        }
        if (this.sidebarMenu[i].url == url) this.sidebarMenu[i].active = true;
        else {
          this.sidebarMenu[i].active = false;
          this.sidebarMenu[i].menu = false;
        }
      }
      if (url != "#") {
        this.router.navigate([url]);
      }
    }

  }

  goToChildRoute(id, url) {
    this.sidebarMenu.find(x => x.id == id).active = true;
    let childMenu = this.sidebarMenu.find(x => x.id == id).child;
    if (childMenu.length > 0) {
      for (let j = 0; j < childMenu.length; j++) {
        if (childMenu[j].url == url) childMenu[j].active = true;
        else childMenu[j].active = false;
      }
    }
    //this.sidebarMenu.find(x=> x.id != id && x.active).active=false;
    let menu = this.sidebarMenu.find(x => x.id != id && x.active);
    if (menu != null && menu != undefined) {
      if (menu.child.length > 0) {
        this.sidebarMenu.find(x => x.id != id && x.active).menu = false;
        this.sidebarMenu.find(x => x.id != id && x.active).active = false;
        for (let k = 0; k < menu.child.length; k++) {
          menu.child[k].active = false;
        }
      }
      else {
        this.sidebarMenu.find(x => x.id != id && x.active).active = false;
      }
    }
    //console.log(this.sidebarMenu)
    this.router.navigate([url]);
  }
  logOut() {
    // localStorage.removeItem('token_en');
    // localStorage.removeItem('isOwnerLoggedIn'); 
    // localStorage.removeItem('adminDTO'); 
    localStorage.clear();
    this.router.navigate(['/login']).then(() => {
      this.toastr.success('Logout Successfully! ', '', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
    })

  }

}
