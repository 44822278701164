import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sorting',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.css']
})
export class SortingComponent implements OnInit {
  @Input() title= "";
  @Input() name ='';
  @Input() oldData: any=[];
  @Output() changeData = new EventEmitter<any>();
  upsorting: boolean = false;
  downsorting: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }
  sortingUpDown(){
    let data=[];
    this.upsorting = !this.upsorting; 
    if(this.upsorting) {
      this.downsorting = false;      
      data = this.oldData.sort(this.compareValues(this.name));
    }
    else {
      this.downsorting = true;  
      data = this.oldData.sort(this.compareValues(this.name, 'desc'));     
    }
    if(data != null && data != undefined){
      this.changeData.emit(data);
    }   
    else{
      this.changeData.emit(this.oldData);
    }   
  }
   compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {      
        return 0;
      }
  
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }
}
