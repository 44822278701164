import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '@services/dto.service';
import { FunctService } from '@services/funct.service';
import { DatePipe, Location } from '@angular/common';

import { from } from 'rxjs';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { isThisISOWeek } from 'date-fns';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
declare var $: any;

import { ApiTokenService } from '@services/api-token.service';

@Component({
  selector: 'app-banktype-detail',
  templateUrl: './banktype-detail.component.html',
  styleUrls: ['./banktype-detail.component.css']
})
export class BanktypeDetailComponent implements OnInit {
  banktypeId: any;
  banktypeDTO: any;
  imagePath: any;
  imgURL: any;
  message: string = '';
  CountryList =[];
  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService, private apiTokenServer: ApiTokenService,
    private activatedRoute: ActivatedRoute,private location: Location) { 

      this.banktypeId = this.route.snapshot.paramMap.get('id');
    
     /* this.activatedRoute.queryParams.subscribe(params  => {
        this.banktypeId = params.id;
      })*/

      

    }

  ngOnInit(): void {

    if (this.banktypeId != null) {
      this.banktypeDTO = {
        id: 0,
        name: '',          
        logo_url: '',
        url: '',
        minAcceptAmount: 0,
        maxAcceptAmount: 0,        
        status: 'ACTIVE',
        country_id :0,
        country_name : ''
      };
      this.getResultById();
      this.getAllCountry();
    }
  }

  async getAllCountry() {    
    this.CountryList = [];
    await this.apiTokenServer.get(`${this.funct.ipaddress}country/getallcountry`,true)
      .then(response => {
        
        if(response.data.data !=null){
          let data = JSON.parse(response.data.data);
          console.log(data)
          var newResult= [];
          newResult.push({id:0,text: 'Select Country'});   
          data.forEach(arr => {
            let text= arr.name;
            newResult.push({...arr,...{text: text}});          
          });
          this.CountryList = newResult;
        } else{         
          this.CountryList =[];         
        }       
      
        this.spinner.hide();
      })
      .catch(error => {        
          this.toastr.error("Limited Access.", 'Forbidden!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        
      });
    
  }

  flagRole(){
    this.banktypeDTO.country_id = $("#country_id").val();    

  }

  getResultById() {   
    this.spinner.show();
    this.apiTokenServer.get(`${this.funct.ipaddress}banktype/GetBankTypeDetailsById?id=${this.banktypeId}`)   
    .then(response => {
      if(response.status == 200){  
        console.log(response.data)     
        this.spinner.hide(); 
        this.banktypeDTO = response.data;     

          if (this.banktypeDTO.logo_url != null || this.banktypeDTO.logo_url != '') {
            this.imgURL = this.banktypeDTO.logo_url;
            console.log("imgURL getResultById>>>" + JSON.stringify(this.imgURL));
          }    
      }      
    })
    .catch(error => {
      this.spinner.hide();
        this.toastr.error(error.response.data.message, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
    });
  }

  goSave() {
    
    this.edit();
  }

  edit() {

    let api="banktype/updateBankType";
    console.log(this.banktypeDTO.name)
    this.spinner.show();
    if (this.banktypeDTO.name != '') {
     

        if (this.imgURL == '' || this.imgURL == null || this.imgURL == undefined) {
          this.spinner.hide();
          this.toastr.error('Please upload image.', 'Invalid input!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          return;
        }       
       
        if (this.imgURL != '' || this.imgURL != null || this.imgURL != undefined) {
          if (this.imgURL.includes('data:image/jpeg;base64,'))
            this.banktypeDTO.logo_url = this.imgURL.replace("data:image/jpeg;base64,", "");
          if (this.imgURL.includes('data:image/png;base64,'))
            this.banktypeDTO.logo_url = this.imgURL.replace("data:image/png;base64,", "");
        }

       

        let modelStr= JSON.stringify(this.banktypeDTO);
        this.apiTokenServer.post(`${this.funct.ipaddress}${api}`,modelStr,true)
        .then(response => {               
          if(response.data.status == 'Success') {
            this.spinner.hide();
            this.toastr.success(response.data.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
            this.location.back();          
          }
          else {
            this.spinner.hide();
            this.toastr.error(response.data.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          } 
        })
        .catch(error => {
          this.spinner.hide();
            this.toastr.error(error.response.data.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
        });     
      }//
      else {
        this.spinner.hide();
        this.toastr.error('Please enter name.', 'Invalid input!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    }
    
    preview(files) {
      if (files.length === 0)
        return;
  
      var mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        this.message = "Only images are supported.";
        return;
      }
  
      var reader = new FileReader();
      this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
        console.log("imgURL>>>" + JSON.stringify(this.imgURL));
      }
    }
    
  }



