import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterService } from '../../../service/filter.services';

@Component({
  selector: 'app-pagin-header',
  templateUrl: './pagin-header.component.html',
  styleUrls: ['./pagin-header.component.css']
})
export class PaginHeaderComponent implements OnInit {
  @Input() pageLength = 50;
  @Output() changePageSize = new EventEmitter<number>();
  pages: any;
  
  constructor(private filterService: FilterService) { }

  ngOnInit(): void {
    this.pages =[
      {key: 1, isSelected: false},
      {key: 20, isSelected: false},
      {key: 50, isSelected: false},
      {key: 100, isSelected: false},
      {key: 200, isSelected: false},
      {key: 500, isSelected: false},
      {key: 1000, isSelected: false},
      {key: 3000, isSelected: false},
      {key: 5000, isSelected: false}
    ]
    let hasInList=false;
    this.pages.forEach(item => {
      if(item.key==this.pageLength){
        hasInList=true;
      }
    });
    if(!hasInList){
      this.pages.push({key: this.pageLength, isSelected: true});
    }

   //let aa= this.pages.length;
    
  }

  change(val:number){
    this.changePageSize.emit(val);
  }

  onSearchChange(searchTerm: string) {
    this.filterService.changeSearchTerm(searchTerm);
  }
}
