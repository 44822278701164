import { Component, OnInit, ViewChild } from '@angular/core';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { FunctService } from '@services/funct.service';
import { ApiTokenService } from '@services/api-token.service';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  adminList: any=[];
  model: any={
    phone_no: '',
    name: '',
    referralCode: '',   
    status: '',
    pageNumber: 1,
    pageSize: 20,
    rowsfound: 0,    
  }
  constructor(private storage: LocalStorageService, 
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,  
    private router: Router, 
    private funct: FunctService,
    private apiTokenServer: ApiTokenService,
    ) {
     
      this.search();
  }

  ngOnInit(): void {

  }

  async search() {
    this.adminList = [];   
    this.spinner.show();   
    let modelStr = JSON.stringify(this.model);
    await this.apiTokenServer.post(`${this.funct.ipaddress}authenticate/adminsByparams`,modelStr,true)    
    .then(response => {
      if(response.status == 200 && response.data != null){       
        var result = JSON.parse(response.data.data);  
        this.adminList = result.results;
        this.model.rowsfound = result.totalRows;
        this.spinner.hide();
      }
      else{
        this.spinner.hide();
        this.toastr.error(response.data.message, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });        
      }      
    })
    .catch(error => {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Forbidden!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    });
  }
 
}