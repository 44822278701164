<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">Dashboard</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- Info boxes -->
      <div class="row">
        <div class="col-lg-3 col-6">
        
        <div class="small-box bg-info">
        <div class="inner">
        <h3>{{pendingcount}}</h3>
        <p>Total Pending</p>
        </div>
        <div class="icon">
        <i class="ion ion-bag"></i>
        </div>
        <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
        </div>
        
        <div class="col-lg-3 col-6">
        
        <div class="small-box bg-success">
        <div class="inner">
        <h3>{{deniedcount}}<sup style="font-size: 20px"></sup></h3>
        <p>Total  DENIED</p>
        </div>
        <div class="icon">
        <i class="ion ion-stats-bars"></i>
        </div>
        <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
        </div>

        <div class="col-lg-3 col-6">
        
          <div class="small-box bg-danger">
          <div class="inner">
          <h3>{{approvedcount}}</h3>
          <p>Total Approved</p>
          </div>
          <div class="icon">
          <i class="ion ion-pie-graph"></i>
          </div>
          <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
          </div>
        
        <div class="col-lg-3 col-6">
        
        <div class="small-box bg-warning">
        <div class="inner">
        <h3>{{total_active_users}}</h3>
        <p>Users</p>
        </div>
        <div class="icon">
        <i class="ion ion-person-add"></i>
        </div>
        <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
        </div>
        
       
        
        </div>

 
      <!-- /.row -->
 <div class="row">
  <div class="col-lg-12">    
    <div class="card">
    <div class="card-header border-0">
    <h3 class="card-title">Latest Rate</h3>
    <div class="card-tools">    
    </div>
    </div>
    <div class="card-body">
    <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
    <p class="text-success text-xl">
     {{ this.ratemodel?.from_name }} _ {{ this.ratemodel?.from_currency }}
    <i class="ion ion-android-arrow-forward text-success"></i> 
    {{ this.ratemodel?.to_name }} _ {{ this.ratemodel?.to_currency }}
    </p>
    <p class="d-flex flex-column text-right">
    <span class="font-weight-bold">
    <i class="ion ion-android-analytics-outline-success"></i> {{ this.ratemodel?.to_rate }}
    </span>
    <span class="text-muted">Exchange RATE</span>
    </p>
    </div>
    
    
    
    </div>
    </div>
    </div>
 </div>
      <!-- /.row -->
    </div><!--/. container-fluid -->
  </section>
  <!-- /.content -->
</div>

    


   
    