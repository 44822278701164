import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class FunctService {

  public ipaddress: any;
  public secretKey: any;

  constructor(

  ) {

    //.Net api
    //this.ipaddress = "https://localhost:44392/api/"; 

    // live api
     //this.ipaddress = "https://skapi.securekyat.com/api/";

    //demoapi
    this.ipaddress = "https://demoapi.securekyat.com/api/";

    this.secretKey = "Yv9GlO0wX4peYxWCMGpUXM9ZKJBU78tc8cvSld5sN20";
  }
  encrypt(plainText: string): string {
    var key = CryptoJS.enc.Utf8.parse('7061737323313233');
    var iv = CryptoJS.enc.Utf8.parse('7061737323313233');

    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), key,
      {
        keySize: 128,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    //console.log(encrypted.toString());
    return encrypted.toString();
  }

  getSignature(signature: string): string {
    var sin = Md5.hashStr(signature).toString();
    return sin;
  }



}