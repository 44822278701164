import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams ,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


import { FunctService } from '../../service/funct.service';

import { NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeSelectedFiles } from 'ngx-scanner-qrcode';
import { ApiTokenService } from '../../service/api-token.service';
declare var $: any;

@Component({
  selector: 'app-payment-modify',
  templateUrl: './payment-modify.component.html',
  styleUrls: ['./payment-modify.component.css']
})
export class PaymentModifyComponent implements OnInit {

  id:any;
  model: any={country_id:0,bankType_id:0,bankType_name:'',account_no:'',account_name:'',typefor:'',status:'ACTIVE',today_reach_amt:0,
  order_id:0,day_maxLimit:0,isfor_Topup:'true',remark:'',balance:0,handle_admin:'',
  isshow_qr:'false',topup_qrcode:''};
  paymentList : any;
  CountryList = [];
  adminList = [];
  myOptions: IMultiSelectOption[];
  myUpdOptions: IMultiSelectOption[];
  optionsModel: number[];

  constructor( private apiTokenServer:ApiTokenService,
    private qrcode: NgxScannerQrcodeService,
    private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient,  private router: Router,
    private route: ActivatedRoute, private funct: FunctService) { 
    this.id = this.route.snapshot.paramMap.get('id');
      this.getAllCountry();
      this.getData();

  }

  async ngOnInit(): Promise<void> {
 
 
    this.getAllAdmins();
  }


   async getData()
  {
  this.spinner.show();
  await  this.apiTokenServer.post(this.funct.ipaddress + 'bankaccount/Details',JSON.stringify(this.id),true,true).then(
      async response => {
        if(response.data.data!=null){
        this.model = JSON.parse(response.data.data);
        this.model.isshow_qr=this.model.isshow_qr.toString();
        this.optionsModel=this.model.adminIdList;
        //this.model.isfor_Topup=this.model.isfor_Topup.toString();
         //await this. getBankTypeList();
         this.getBankTypeList();
           this.spinner.hide();
        }
      }
    );
    this.spinner.hide();
  }

  async getAllCountry() {    
    this.CountryList = [];
    await this.apiTokenServer.get(`${this.funct.ipaddress}country/getallcountry`,true)
      .then(response => {
        
        if(response.data.data !=null){
          let data = JSON.parse(response.data.data);
          //console.log(data)
          var newResult= [];
         // newResult.push({id:0,text: 'Select Country'});   
          data.forEach(arr => {
            let text= arr.code;
            newResult.push({...arr,...{text: text}});          
          });
          this.CountryList = newResult;
        } else{         
          this.CountryList =[];         
        }       
      
        this.spinner.hide();
      })
      .catch(error => {        
          this.toastr.error("Limited Access.", 'Forbidden!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        
      });
    
  }

  fromCountry(){
    this.model.country_id = $("#country_id").val(); 
    if(this.model.country_id != 0){
      this.getBankTypeList();
    }

    }
  
  
  getAllAdmins()
  {
   let data= localStorage.getItem('adminList');
   if(data!=undefined&& data!=null &&data!='undefined'){
    this.adminList = JSON.parse(data);
    this.myOptions = this.adminList;
   }
    this.adminList =[];
 
    this.apiTokenServer.post(this.funct.ipaddress + 'authenticate/list',JSON.stringify(""),true,true).then(
      response => {
        if(response.data.data!=null){
        this.adminList = JSON.parse(response.data.data);
        this.myOptions = this.adminList;
        localStorage.setItem('adminList', JSON.stringify(this.adminList));
        }
      }
    );
  }
  
 
  

  async getBankTypeList() {
    let dataList= localStorage.getItem("banktypeListByCountry");
   if(dataList!=undefined && dataList!=null){
    this.paymentList = JSON.parse(dataList);
   }
   let model=JSON.stringify(this.model.country_id);
    await  this.apiTokenServer.post(this.funct.ipaddress + 'banktype/getbankTypeByCountry',model,true,true)
    .then(response => {
      this.paymentList = response.data;
        localStorage.setItem("paymentList",JSON.stringify(this.paymentList));
    });
    
  }

    
  changeBankType(id,bankName) 
  {
   this.model.bankType_id=id;
   this.model.bankType_name=bankName;
  }
  
 
  flagAdmin()
  {
    this.model.handle_admin = $("#admin_id").val();
  }
 
  

  
  async save() {

    
    if( this.model.id == "" || this.model.id  == undefined ||  this.model.id  == 0)
    {
       this.toastr.error("Bad request", '', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
       return;
    }

    if( this.model.country_id == "" || this.model.country_id  == undefined ||  this.model.country_id  == null ||this.model.country_id==0)
    {
       this.toastr.error("Please choose country", '', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
       return;
    }
    if( this.model.bankType_id == "" || this.model.bankType_id  == undefined ||  this.model.bankType_id  == null ||this.model.bankType_id==0)
    {
       this.toastr.error("Please choose bank type", '', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
       return;
    }


           if( this.model.account_no == "" || this.model.account_no  == undefined ||  this.model.account_no  == null)
           {
              this.toastr.error("Please enter account no", '', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
              return;
           }
           
           if( this.model.account_name == "" || this.model.account_name  == undefined ||  this.model.account_name  == null)
           {
              this.toastr.error("Please enter account name", '', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
              return;
           }
           let mobNumberPattern =/[0-9]/g;
           if (!mobNumberPattern.test(this.model.account_no))
           {
             this.toastr.error("Account number must be number", '', {
               timeOut: 3000,
               positionClass: 'toast-top-right',
             });
             return;
           }
     
           if(this.model.limited_amt == 0)
           {
              this.toastr.error("Limited amount must not be zero", '', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
              return;
           }
           if(this.optionsModel.length > 0)
           {
            this.model.handle_admin = this.optionsModel.toString();
           }
          if( this.model.handle_admin.length == 0 || this.model.handle_admin == "" || this.model.handle_admin  == undefined ||  this.model.handle_admin  == null)
           {
             this.toastr.error("Please choose account holder (Admin)", '', {
               timeOut: 3000,
               positionClass: 'toast-top-right',
             });
             return;
           }
    this.spinner.show();

    
    this.model.handle_admin = this.optionsModel.toString();
    let dataTosend=JSON.stringify(this.model);
  await  this.apiTokenServer.post(this.funct.ipaddress + 'bankaccount/Edit',dataTosend,true,true).then(
      response => {
        if(response.data.statusCode==200){
           this.spinner.hide();
          this.router.navigate(['/payment-info']).then(() => {
                this.toastr.success(response.data.message, 'Success!', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right'
                  });
                })
        }else{
          this.spinner.hide();
          this.toastr.error(response.data.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
        }
      }
    );
    this.spinner.hide();
    
  }


}
