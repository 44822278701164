<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
                <div class="col-sm-8">
                    <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;">
                        Bank Type List
                    </h3>
                </div>
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <form>
                <div class="row">                

                    

                    
                    <div class="form-group col-md-2">
                        <label> Country </label>

                        <select (change) = "flagRole()" id = "country_id" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                        [(ngModel)]="this.MyModel.country_id" [ngModelOptions]="{standalone: true}">
                         <option *ngFor="let country of CountryList" value = "{{country.id}}" >
                           {{country.name}}
                         </option>
                      </select>
                        
                    </div>

                    <div class="form-group col-md-2">
                        <label> Status </label>
                        <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                            [(ngModel)]="this.MyModel.status" [ngModelOptions]="{standalone: true}">
                            <option value="" selected>All</option>
                            <option value="ACTIVE" selected>ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                        </select>
                    </div>

                   


                    <div class="form-group col-md-2">
                    </div>

                    <div class="form-group col-md-1 col-sm-4 col-xs-4">
                        <label></label>
                        <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                            style="margin-top: 2%;" (click)="search()">
                            Search
                        </button>
                    </div>

                    <div class="form-group col-md-1 col-sm-4 col-xs-4">
                        <label></label>
                        <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 2%;"
                            [routerLink]="['/banktype-info']">
                            Add New
                        </button>
                    </div>

                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title" style="font-weight: bold;"> Bank Type List </h3>
                            </div>
                            <!-- /.card-header id="{{'tblpayment' + idIndex}}"-->
                           
                            <div class="card-body">
                              <app-pagin-header (changePageSize)="changePageSize($event)" [pageLength]="MyModel.pageSize"></app-pagin-header>               

                              <table width='100%' 
                                  class="table table-bordered table-hover">
                                   <thead>
                                    <tr>
                                        <th style="font-family: unicode;">Bank Name</th>
                                        <th style="font-family: unicode;">Country</th> 
                                        <th style="font-family: unicode;">Status</th> 
                                        <th style="font-family: unicode;">MinnAcceptAmount</th>
                                        <th style="font-family: unicode;">MaxnAcceptAmount</th>
                                       
                                      
                                        <th style="font-family: unicode;">Created Date</th>
                                        <th style="font-family: unicode;">Updated Date</th>
                                        <th style="font-family: unicode;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let banktype of resultlist">

                                        <td style="font-family: unicode;">{{banktype?.name}}</td>     
                                        <td style="font-family: unicode;">{{banktype?.country_name}}</td>                                            
                                        <td style="font-family: unicode;">
                                          <input type="radio" [checked]="banktype?.status=='ACTIVE'"></td>                              
                                        <td style="font-family: unicode;">{{banktype?.minAcceptAmount}}</td>
                                        <td style="font-family: unicode;">{{banktype?.maxAcceptAmount}}</td>                             
                                        <td style="font-family: unicode;">{{banktype?.created_date}}</td>
                                        <td style="font-family: unicode;">{{banktype?.updated_date}}</td>
                                        <td style="font-family: unicode;">
                                            <a [routerLink]="['/banktype-detail', banktype.id]">
                                                <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                            </a>                                     
                                          

                                        </td>

                                    </tr>

                                    <tr *ngIf="resultlist.length == 0 ">
                                        <td colspan="16">
                                        <div class="d-flex justify-content-center"> No data available in table </div>
                                        </td>
                                      </tr>               
                                </tbody>
                              </table>
                          </div>


<div class="pag_nav"><app-pagin-footer (changePage)="changePage($event)" [pageLength]="MyModel.pageSize" [pageIndex]="MyModel.pageNumber" [rowsFound]="MyModel.rowsfound"></app-pagin-footer>
</div>
                          

                            <!-- /.card-body -->
                        </div>
                    </div>

            
                </div>
            </form>
            <!-- /.row -->
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
    <!-- /.content -->
</div>

