import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DtoService } from '../app/service/dto.service';
import { UtilService } from '../app/service/util.service';
import { FunctService } from '../app/service/funct.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgxDatePickerModule } from '@ngx-tiny/date-picker';
import { MatDialogModule} from '@angular/material/dialog'; 
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ServerErrorInterceptor} from './interceptors/server-error.interceptor';
import { LoginComponent } from './component/login/login.component';
import { PageNotfoundComponent } from './component/page-notfound/page-notfound.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ChangepasswordComponent } from './component/changepassword/changepassword.component';
import { ChangepasswordDetailComponent } from './component/changepassword-detail/changepassword-detail.component';
import { PaymentComponent } from './component/payment/payment.component';
import { PaymentDetailComponent } from './component/payment-detail/payment-detail.component';
import {PaymentInfoComponent} from './component/payment_info/payment_info.component';
import {PaymentDetailInfoComponent} from './component/payment_info_detail/payment-detail-info.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MDBBootstrapModule, ModalModule } from 'angular-bootstrap-md';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TwoDigitDecimaNumberDirective } from './component/two-digit-decima-number.directive';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AlertDialogComponent } from './component/dialog/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './component/dialog/confirm-dialog/confirm-dialog.component';
import { SystemMainteneceComponent } from './component/system-maintenece/system-maintenece.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaymentModifyComponent } from './component/payment-modify/payment-modify.component';
import { NgSelect2Module } from 'ng-select2';
import { BanktypeComponent } from './component/banktype/banktype.component';
import { BanktypeDetailComponent } from './component/banktype-detail/banktype-detail.component';
import { BanktypeInfoComponent } from './component/banktype-info/banktype-info.component';



// import { FloatingChatComponent } from './component/floating-chat/floating-chat.component';
// import { ChatComponentComponent } from './component/chat-component/chat-component.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    PageNotfoundComponent,
    ChangepasswordComponent,
    ChangepasswordDetailComponent,
    
    PaymentComponent,
    PaymentDetailComponent,  
    
    PaymentInfoComponent,
    PaymentDetailInfoComponent,  
    TwoDigitDecimaNumberDirective,            
    AlertDialogComponent,
    ConfirmDialogComponent,   
    SystemMainteneceComponent,
    PaymentModifyComponent,
    BanktypeComponent,
    BanktypeDetailComponent,
    BanktypeInfoComponent,
    
    
    
    // FloatingChatComponent,
    // ChatComponentComponent,

  ],
  imports: [
    ModalModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    DataTablesModule,
    NgxDatePickerModule,
    NgxPaginationModule,
    MDBBootstrapModule.forRoot(),
    NgxBootstrapMultiselectModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularEditorModule,
    MatDialogModule,
    MatButtonModule,
    QRCodeModule,
    NgxScannerQrcodeModule,
    BsDatepickerModule.forRoot(),
    SharedModule,
    NgSelect2Module,
       
  ],
  providers: [
    DtoService,
    UtilService,
    FunctService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
  ],
   
  bootstrap: [AppComponent]
})
export class AppModule { }
