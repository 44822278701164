import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { FunctService } from '../../service/funct.service';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';


import { Subject } from 'rxjs';

@Component({
  selector: 'app-system-maintenece',
  templateUrl: './system-maintenece.component.html',
  styleUrls: ['./system-maintenece.component.css']
})
export class SystemMainteneceComponent implements OnInit {

  public errorMessage: any;

  constructor(private _route: ActivatedRoute, private _router: Router, private http: HttpClient, private funct: FunctService) {

  }

  ngOnInit(): void {
    this.errorMessage = this._route.snapshot.paramMap.get('message');
    this.refreshPage();
  }


  refreshPage() {
    interval(2*60*1000).subscribe(x => {
      let headers = new HttpHeaders();
      this.http.get(this.funct.ipaddress + 'threedconfig/3d_close_time', { headers: headers })
      .pipe(        
       )
      .subscribe(
        result => {
          this._router.navigate(['ad-login']);
        }
      );


    }); //end of inter

  }



  checkX(x) {
    console.log(x)
  }

}
