<nav class="main-header navbar navbar-expand navbar-white navbar-light" style="background-color:#4b545c;">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars" style="color: white;"></i></a>
      </li>
  </ul>

  <!-- SEARCH FORM -->
  <form class="form-inline ml-3">
    <div class="input-group input-group-sm">
      <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
      <div class="input-group-append">
        <button class="btn btn-navbar" type="submit">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </form>
</nav>
<!-- /.navbar -->

<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4" id="menu">
  <!-- Brand Logo -->
  <a [routerLink] = "['/dashboard']" class="brand-link">
    <img src="assets/image/kyat.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
         style="opacity: .8">
    <span class="brand-text font-weight-light">SecureKyat Admin</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar" id="sb">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img src="assets/image/avatar2.png" class="img-circle elevation-2" alt="User Image">
      </div>
      <div class="info">
        <a class="d-block">{{adminPhone}}</a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <!-- class="menu-open" -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">   
        <li [ngClass]="{'nav-item': !menu.header, 'nav-header': menu.header, ' has-treeview' : menu.child.length > 0 ,' menu-open' : menu.menu , '' : !menu.menu}" *ngFor="let menu of sidebarMenu; let i=index;">
          
          <b *ngIf="menu.header">{{menu.title}}</b>
          <a *ngIf="!menu.header" class="nav-link" [ngClass]="{'active': menu.active}"  (click)="goToRoute(menu.id, menu.url)" >
              <i [className]="'fas ' + menu.icon+ ' nav-icon'"></i>
              <p>{{menu.title}}</p>
              <i class="fas fa-angle-left right" *ngIf="menu.child.length > 0"></i>
          </a>          
          <ul class="nav nav-treeview" [ngStyle]="{'display': menu.menu? 'block' : 'none'}" >           
            <li class="nav-item" *ngFor="let childItem of menu.child">
              <a  class="nav-link" [ngClass]="{'active': childItem.active}" (click)="goToChildRoute(menu.id, childItem.url)">
                <i [className]="'fas ' + childItem.icon+ ' nav-icon'"></i>
                <p>
                  {{childItem.title}}
                </p>
              </a>
            </li>            
          </ul>
        </li>

        <!-- <li class="nav-item">
          <a [routerLink] = "['/dashboard']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
              <i class="fas fa-tachometer-alt nav-icon"></i>
              <p>Dashboard</p>
          </a>
        </li>
        <li class="nav-header"><b>MANAGEMENT</b></li>
        <li class="nav-item">
          <a [routerLink]="['/admin']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-user-shield"></i>
            <p>
              Admin
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/agent']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-user-tie"></i>
            <p>
              Agent
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/customer-service']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-handshake" aria-hidden="true"></i>
            <p>
              Customer Service
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/sale-promoter']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-envelope-open-text" aria-hidden="true"></i>
            <p>
              Sale Promoter
            </p>
          </a>
        </li>

        <li class="nav-item has-treeview">
          <a href="#" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{exact:false}">
            <i class="nav-icon fas fa-users"></i>
            <p>
              Users
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">           
            <li class="nav-item">
              <a [routerLink]="['/user']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-user"></i>
                <p>
                  User
                </p>
              </a>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/user/point-promotion-users']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-star"></i>
                <p> Point Promotion Users </p>
              </a>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/user/confirmed-promotion-users']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
               <i class="nav-icon fas fa-star"></i> 
                <p> Confirmed Promotion Users </p>
              </a>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/user/level']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
               <i class="nav-icon fas fa-coins"></i> 
                <p> Pay User Level</p>
              </a>
            </li>          

          </ul>
        </li>

        <li class="nav-header"><b>BUSINESS</b></li>
        <li class="nav-item has-treeview">
          <a href="#" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-bullhorn"></i>
            <p>
              Winning Number
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
           
            <li class="nav-item">
              <a [routerLink]="['/win-number/twod']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="far fa-list-alt nav-icon"></i>
                <p> 2D Result </p>
              </a>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/win-number/threed']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="far fa-list-alt nav-icon"></i>
                <p> 3D Result </p>
              </a>
            </li>

            <li class="nav-item" style= "display : none">
              <a [routerLink]="['/win-number/twod-live-result']" class="nav-link">
                <i class="fas fa-wifi nav-icon"></i>
                <p> 2D Live Result </p>
              </a>
            </li>

			 <li class="nav-item">
              <a [routerLink]="['/win-number/twod-winners-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="fas fa-trophy nav-icon"></i>
                <p> 2D Winner List </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/win-number/threed-winners-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="fas fa-trophy nav-icon"></i>
                <p> 3D Winner List </p>
              </a>
            </li>
          </ul>
        </li> -->

        <!-- <li class="nav-item has-treeview">
          <a href="#" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-comments-dollar"></i>
            <p>
              Transaction
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['/transaction/topup-check-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="fas fa-hand-holding-usd nav-icon"></i>
                <p>Top-up</p>
              </a>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/transaction/withdraw-check-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="fas fa-file-invoice-dollar nav-icon"></i>
                <p>Withdraw</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/transaction/twodbet-record-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="fa fa-file nav-icon"></i>
                <p>2D Bet History</p>
              </a>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/transaction/threedbet-record-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="fa fa-file nav-icon"></i>
                <p>3D Bet History</p>
              </a>
            </li>
			
            <li class="nav-item">
              <a [routerLink]="['/transaction/twod-bet-amount-limitation-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="fas fa-coins nav-icon"></i>
                <p> 2D Bet Amount Limitation</p>
              </a>
            </li>

           <li class="nav-item" >
              <a [routerLink]="['/transaction/threed-bet-amount-limitation-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="fas fa-coins nav-icon"></i>
                <p> 3D Bet Amount Limitation</p>
              </a>
            </li>
            		      
          </ul>
        </li> 
        <li class="nav-item">
          <a [routerLink]="['/twod-result-out']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="far fa-list-alt nav-icon"></i>
            <p>Result Out (2D) List</p>
          </a>
        </li>
        -->
        
        <!-- <li class="nav-item has-treeview" style = "display: none;">  
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-comments-dollar"></i>
            <p>
              Commission
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        <ul class="nav nav-treeview">
          <li class="nav-item">
              <a [routerLink]="['/commission']" class="nav-link">
                <i class="nav-icon fas fa-calculator" ></i>
                <p>
                Calculation
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/commission-confirmation']" class="nav-link">
                <i class="nav-icon fas fa-phone-square-alt"></i>
                <p>
                  Confirmation
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/agent-withdraw']" class="nav-link">
                <i class="fas fa-file-invoice-dollar nav-icon"></i>
                <p>
                  Agent Withdraw
                </p>
              </a>
            </li>
          </ul>
        </li>


        <li class="nav-item has-treeview"> 
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-gamepad"></i>
              <p>
                Game
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                  <a [routerLink]="['/game/gs']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                    <i class="nav-icon fas fa-crown" aria-hidden="true"></i>
                    <p>
                      Games List
                    </p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/game/provider']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                    <i class="nav-icon far fa-life-ring" aria-hidden="true"></i>
                    <p>
                    Game Providers List
                    </p>
                  </a>
              </li>
              <li class="nav-item">
                <a [routerLink]="['/game/alert']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                  <i class="nav-icon far fa-life-ring" aria-hidden="true"></i>
                  <p>
                  Game Alert List
                  </p>
                </a>
            </li>
            </ul>

        </li>
       

        <li class="nav-item">
          <a [routerLink]="['/threed-result-configuration']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-file-alt nav-icon"></i>
            <p>3D Result Configuration</p>
          </a>
        </li>
        
       <li class="nav-item has-treeview">
          <a href="#" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-file"></i>
            <p>
              Reporting
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['/report/twod-profit-loss']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                  2D Profit Loss Report
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/report/twod']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                  2D Bet History Report
                </p>
              </a>
            </li>            
            <li class="nav-item" >
              <a [routerLink]="['/report/twod-detail']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                  2D Bet History Detail Report
                </p>
              </a>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/report/threed']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                 3D Bet History Report
                </p>
              </a>
            </li>
        
            <li class="nav-item">
              <a [routerLink]="['/report/twodwinnerlist']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                  2D Winner List Report
                </p>
              </a>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/report/threedwinnerlist']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                  3D Winner List Report
                </p>
              </a>
            </li>

            <li class="nav-item" style="display: none;">
              <a [routerLink]="['/report/userstatements']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                 User Statements Report
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/report/userfinancial']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                  User Financial Report
                </p>
              </a>
            </li>
            
            <li class="nav-item">
              <a [routerLink]="['/report/userregistration']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                 User Registration Report
                </p>
              </a>
            </li> 

            <li class="nav-item">
              <a [routerLink]="['/report/promotercommission']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                Promoter Commission Report
                </p>
              </a>
            </li> 
           
            <li class="nav-item">
              <a [routerLink]="['/report/firsttopup']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
               First Topup Report Monthly
                </p>
              </a>
            </li> 

            <li class="nav-item">
              <a [routerLink]="['/report/firsttopup-daily']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
               First Topup Report Daily
                </p>
              </a>
            </li> 

            <li class="nav-item">
              <a [routerLink]="['/report/winner-monthly']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
               Winner Report Monthly
                </p>
              </a>
            </li> 

            <li class="nav-item">
              <a [routerLink]="['/report/bankaccountdetail']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                Bank Account Detail Report
                </p>
              </a>
            </li> 

            <li class="nav-item" style="display: none;">
              <a [routerLink]="['/report/bankaccountdetail-beforeclean']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                (2) Bank Account Detail Report
                </p>
              </a>
            </li> 
        
            <li class="nav-item">
              <a [routerLink]="['/report/withdrawalbankacc']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                Withdraw Bank Account Report
                </p>
              </a>
            </li> 

            <li class="nav-item">
              <a [routerLink]="['/report/twodbethistoryrpt50percent']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                  2D Bet History Report (50%)
                </p>
              </a>
            </li> 

            <li class="nav-item">
              <a [routerLink]="['/report/twodbethistoryrpt35percent']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                  2D Bet History Report (35%)
                </p>
              </a>
            </li> 
            <li class="nav-item" style="display: none;">
              <a [routerLink]="['/report/twodBetLimit']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                  2D Bet Amount Limit Report
                </p>
              </a>
            </li>
            <li class="nav-item" style="display: none;">
              <a [routerLink]="['/report/threedbetlimit']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="nav-icon fas fa-file"></i>
                <p>
                  3D Bet Amount Limit Report
                </p>
              </a>
            </li> 

          </ul>
        </li> 
       

        <li class="nav-header"><b>INFORMATION</b></li>        
        
        <li class="nav-item">
            <a  href="#" class="nav-link">
                <i class="nav-icon fas fa-ad"></i>
                <p>
                  Promotions
                </p>
                <i class="right fas fa-angle-left"></i>
            </a>
             <ul class="nav nav-treeview">
                     <li class="nav-item">
                        <a [routerLink]="['/promotion/news']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                          <i class="nav-icon fas fa-ad"></i>
                          <p>
                            Promotions and News
                          </p>
                        </a>
                      </li>
      
                      <li class="nav-item">
                        <a [routerLink]="['/promotion/point']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                          <i class="nav-icon fas fa-star"></i>
                          <p>
                          Point Promotion
                          </p>
                        </a>
                     </li>
      
                    <li class="nav-item">
                      <a [routerLink]="['/promotion/discount-entry']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <i class="nav-icon fas fa-percentage"></i>
                        <p>
                          2D/3D Discount Entry
                        </p>
                      </a>
                    </li>
            </ul>
        </li>


        <li class="nav-item">
          <a [routerLink]="['/service-phone']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-phone-square-alt"></i>
            <p>
              Service Phone
            </p>
          </a>
        </li>
        
        <li class="nav-item">
          <a [routerLink]="['/feedback']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-comment-dots"></i>
            <p>
              Feedback
            </p>
          </a>
        </li> 

        <li class="nav-item">
          <a [routerLink]="['/ads']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-ad"></i>
            <p>Ads</p>
          </a>
        </li> 
           -->
        <!-- <li class="nav-item">
          <a [routerLink]="['/marquee']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-paragraph"></i>
            <p>
           Marquee Text
            </p>
          </a>
        </li>
        
        <li class="nav-item">
          <a [routerLink]="['/promotion-ads']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-funnel-dollar"></i>
            <p>Promotion Ads</p>
          </a>
        </li>  -->

        <!-- <li class="nav-item">
          <a [routerLink]="['/notification']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa fa-bell"></i>
            <p>
              Notification
            </p>
          </a>
        </li> -->

        <!-- <li class="nav-header"><b>SOlUTIONS</b></li>

        <li class="nav-item">
          <a [routerLink]="['/unblock-user']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-ban"></i>
            <p>
              Unblock User
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/unblock-userbankacc']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-ban"></i>
            <p>
              Unblock User Bank Account
            </p>
          </a>
        </li>

        <li class="nav-item" >
          <a [routerLink]="['/usrtranlist']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-list-alt nav-icon"></i>
            <p> Duplicate TransactionNo.</p>
          </a>
        </li>

        <li class="nav-item" >
          <a [routerLink]="['/tranadded']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-file nav-icon"></i>
            <p> Added TransactionNo.</p>
          </a>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/maintenance']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-file-alt nav-icon"></i>
            <p>
              Maintenance
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/game-errorlog']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-file-alt nav-icon"></i>
            <p>
              Game Error log
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/withdraw-bankaccountlist']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-file-alt nav-icon"></i>
            <p>
               Unblock Withdraw BankAccount
            </p>
          </a>
        </li> -->
        
        

        <!-- <li class="nav-header"><b>SETTING</b></li>

        <li class="nav-item">
          <a [routerLink]="['/confirguation-detail']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-file-alt nav-icon"></i>
            <p>
              Configuration
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/transfer-bank-account-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-money-bill"></i>
            <p>
              Transfer Bank Account 
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/admin-withdraw-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-file-alt nav-icon"></i>
            <p>
              Admin Withdrawl
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/bank-account-balance']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-file-alt nav-icon"></i>
            <p>
              Bank Account Balance
            </p>
          </a>
        </li>
        
        <li class="nav-item">
          <a [routerLink]="['/payment-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-money-bill"></i>
            <p>
              Payment
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/payment-info']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-money-bill"></i>
            <p>
              Bank Account Detail
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/block-bank-acc-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-ban" aria-hidden="true"></i>
            <p>
              Block Bank Account
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/holiday-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="far fa-calendar-alt nav-icon"></i>
            <p>Holiday</p>
          </a>
        </li>
        
        <li class="nav-item">
          <a [routerLink]="['/oddentry']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa fa-bars"></i>
            <p>
              Odd Entry
            </p>
          </a>
        </li>

        <li class="nav-item has-treeview">  
          <a href="#" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-book-open"></i>
            <p>
              Dream Book
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        <ul class="nav nav-treeview">
              <li class="nav-item">
                <a [routerLink]="['/dream-book']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                  <i class="nav-icon fas fa fa-book"></i>
                  <p>
                  Dream Book
                  </p>
                </a>
              </li>

             
            </ul>
        </li>


        <li class="nav-item has-treeview">  
          <a href="#" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-book-open"></i>
            <p>
              Stream
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        <ul class="nav nav-treeview">
              <li class="nav-item">
                <a [routerLink]="['/stream-modinternet']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                  <i class="nav-icon fas fa fa-wifi"></i>
                  <p>
                  ModernInternet
                  </p>
                </a>
              </li>

              <li class="nav-item">
                <a [routerLink]="['/stream-referralcode']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                  <i class="nav-icon fas fa fa-coins"></i>
                  <p>
                  Referral Code
                  </p>
                </a>
              </li>

             
            </ul>
        </li>
        
        <li class="nav-item" style="display: none;">
          <a [routerLink]="['/needhelp']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-phone nav-icon"></i>
            <p>
              Need Help
            </p>
          </a>
        </li>      

        <li class="nav-item">
          <a [routerLink]="['/changepassword-list']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="nav-icon fas fa-edit"></i>
            <p>
              Change Password
            </p>
          </a>
        </li>

        -->
        <li class="nav-item">
          <a  class="nav-link" (click) = 'logOut()' routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"> 
            <i class="nav-icon fas fa-sign-out-alt"></i>
            <p>
              Log Out
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a >
            <i></i>
            <p>
            </p>
          </a>
        </li> 
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>