
<body class="hold-transition login-page" style="background-color: transparent;">
    <div class="login-box">
      <div class="login-logo" style="color: white;">
        <a><b>SecureKyat</b> Admin</a>
      </div>
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg">Login in to start your session</p>
    
          <form>
            <div class="input-group mb-3"> <!--phoneNo -->
              <input class="form-control" placeholder="Phone No." [(ngModel)]="phone_no" [ngModelOptions]="{standalone: true}">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-mobile-alt"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="Password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="icheck-primary">
                  <input type="checkbox" id="remember">
                  <label for="remember">
                    Remember Me
                  </label>
                </div>
              </div>
              <div class="col-4">
                <button type="submit" class="btn btn-primary btn-block" (click)="login()"> Log In </button>
              </div>
            </div>
          </form>
    
          
        </div>
      </div>
    </div>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
</body>

  


