<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
        <div class="col-sm-8">
          <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;">
            Bank Account List
          </h3>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <form>
        <div class="row">

          <div class="form-group col-md-2">
            <label> Country</label>
            <select (change)="fromCountry()" id="from_country_id" class="form-control form-control-sm"
              [(ngModel)]="this.MyModel.from_country_id" [ngModelOptions]="{standalone: true}">
              <option *ngFor="let country of CountryList" value="{{country.id}}">
                {{country.code}}
              </option>
            </select>
          </div>

          <div class="form-group col-md-2">
            <label> bankName</label>
            <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
              [(ngModel)]="this.MyModel.bankType_id" [ngModelOptions]="{standalone: true}">
              <option value="" selected>All</option>
              <option *ngFor="let acc of bankTypeList" [ngValue]="acc.id">
                {{acc.name}}</option>
            </select>
          </div>

          <div class="form-group col-md-2">
            <label> Account No </label>
            <input class="form-control form-control-sm" [(ngModel)]="this.MyModel.account_no"
              [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group col-md-2">
            <label> Type for </label>
            <select class="typefor form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
              [(ngModel)]="this.MyModel.typefor" [ngModelOptions]="{standalone: true}">
              <option value="" selected>All</option>
              <option value="transfer">Transfer</option>
              <option value="point">Point</option>
            </select>
          </div>





          <div class="form-group col-md-2">
            <label> Status </label>
            <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
              [(ngModel)]="this.MyModel.status" [ngModelOptions]="{standalone: true}">
              <option value="" selected>All</option>
              <option value="ACTIVE" selected>ACTIVE</option>
              <option value="INACTIVE">INACTIVE</option>
            </select>
          </div>




          <div class="form-group col-md-2">
          </div>

          <div class="form-group col-md-1 col-sm-4 col-xs-4">
            <label></label>
            <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 2%;"
              (click)="search()">
              Search
            </button>
          </div>

          <div class="form-group col-md-1 col-sm-4 col-xs-4">
            <label></label>
            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 2%;"
              [routerLink]="['/payment-info-detail']">
              Add New
            </button>
          </div>

          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title" style="font-weight: bold;"> Bank Account List </h3>
              </div>
              <!-- /.card-header id="{{'tblpayment' + idIndex}}"-->

              <div class="card-body">
                <app-pagin-header (changePageSize)="changePageSize($event)"
                  [pageLength]="MyModel.pageSize"></app-pagin-header>

                <table width='100%' class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th style="font-family: unicode;">Country</th>

                      <th style="font-family: unicode;">Bank Name</th>

                      <th style="font-family: unicode;">Status</th>
                      <th style="font-family: unicode;">Account</th>
                      <th style="font-family: unicode;">Name</th>
                      <th style="font-family: unicode;">Type For</th>

                      <th style="font-family: unicode;">DayLimit</th>

                      <th style="font-family: unicode;">Today-Amt</th>
                      <th style="font-family: unicode;">Balance</th>

                      <th style="font-family: unicode;">Show-QR</th>
                      <th style="font-family: unicode;">Index</th>
                      <th style="font-family: unicode;">Created Date</th>
                      <th style="font-family: unicode;">Updated Date</th>
                      <th style="font-family: unicode;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let payment of resultlist">



                      <td style="font-family: unicode;">{{payment?.country_name}}</td>
                      <td style="font-family: unicode;">{{payment?.bankType_name}}</td>


                      <td style="font-family: unicode;">
                        <input type="radio" [checked]="payment?.status=='ACTIVE'">
                      </td>


                      <td style="font-family: unicode;">{{payment?.account_no}}</td>
                      <td style="font-family: unicode;">{{payment?.account_name}}</td>
                      <td style="font-family: unicode;">{{payment?.typefor}}</td>

                      <td style="font-family: unicode;">{{payment?.day_maxLimit}}</td>
                      <td style="font-family: unicode;">{{payment?.today_reach_amt}}</td>
                      <td style="font-family: unicode;">{{payment?.balance}}</td>

                      <td style="font-family: unicode;">
                        <!-- {{payment?.isshow_qr==true?'Yes':'No'}} -->
                        <input type="radio" [checked]="payment?.isshow_qr==true">
                      </td>
                      <td style="font-family: unicode;">{{payment?.order_id}}</td>
                      <td style="font-family: unicode;">{{payment?.created_date}}</td>
                      <td style="font-family: unicode;">{{payment?.updated_date}}</td>
                      <td style="font-family: unicode;">
                        <a [routerLink]="['/payment-info-modify', payment.id]">
                          <i class="fas fa-edit" style="color:#4b88eb;"></i>
                        </a>
                        &nbsp;&nbsp;
                        <a herf="#" id="viewDetailMe" title="View Detail">
                          <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                            (click)="DetailModalDialog(template,payment.id,payment.account_no,payment.bankType_name,payment.account_name)">
                          </i>
                        </a>

                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>


              <div class="pag_nav"><app-pagin-footer (changePage)="changePage($event)" [pageLength]="MyModel.pageSize"
                  [pageIndex]="MyModel.pageNumber" [rowsFound]="MyModel.rowsfound"></app-pagin-footer>
              </div>


              <!-- /.card-body -->
            </div>
          </div>


        </div>
      </form>
      <!-- /.row -->
      <!-- /.row -->
    </div>
    <!--/. container-fluid -->
  </section>
  <!-- /.content -->
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left font-weight-bold">{{ modalService.config.initialState.title }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="row" style="margin-left: 0.1%;">
            <label class="col-md-2"> Bank Type </label>
            <div class="form-group col-md-3">
              <input class="form-control form-control-sm"
                [(ngModel)]="paymentBankAccountHolderDetaiList[0].bankType_name" [ngModelOptions]="{standalone: true}"
                disabled>
            </div>
            <div class="col-md-1"></div>
            <label class="col-md-2">Account Number</label>
            <div class="form-group col-md-3">
              <input class="form-control form-control-sm" [(ngModel)]="paymentBankAccountHolderDetaiList[0].account_no"
                [ngModelOptions]="{standalone: true}" disabled>
            </div>
          </div>

          <div class="row" style="margin-left: 0.1%;">
            <label class="col-md-2">Account Name</label>
            <div class="form-group col-md-3">
              <input class="form-control form-control-sm"
                [(ngModel)]="paymentBankAccountHolderDetaiList[0].account_name" [ngModelOptions]="{standalone: true}"
                disabled>
            </div>
            <div class="col-md-1"></div>

          </div>
          <div class="col-md-12" style="margin-top: 0.5%;">

            <div class="card">
              <div class="card-body">

                <table width='100%' class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th style="font-family: unicode;">No</th>
                      <th style="font-family: unicode;">Account Holder(Admin)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let accDetail of paymentBankAccountHolderDetaiList, let i = index">
                      <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                      <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">
                        {{accDetail.adminName}}
                      </td>
                    </tr>
                  </tbody>
                </table>


              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- 
<div id="browseAccountHolderList" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content" style="width : 700px; margin-left: -100px;">
        <div class="modal-header">
          <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Account Holder (Admin) Detail </h3>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
        </div>
  
        <div class="modal-body">
          <form>
            <div class="col-md-12">
  
              <div class="row" style="margin-left: 0.1%;">
                <label class="col-md-2"> Bank Type </label>
                <div class="form-group col-md-3">
                  <input class="form-control form-control-sm" [(ngModel)]="bankName" [ngModelOptions]="{standalone: true}"
                    disabled>
                </div>
                <div class="col-md-1"></div>
                <label class="col-md-2">Account Number</label>
                <div class="form-group col-md-3">
                  <input class="form-control form-control-sm" [(ngModel)]="account_no"
                    [ngModelOptions]="{standalone: true}" disabled>
                </div>
              </div>
  
              <div class="row" style="margin-left: 0.1%;">
               
                <label class="col-md-2">Code Number</label>
                <div class="form-group col-md-3">
                  <input class="form-control form-control-sm" [(ngModel)]="code_number" [ngModelOptions]="{standalone: true}"
                    disabled>
                </div>
                <div class="col-md-1"></div>
                <label class="col-md-2">Account Name</label>
                <div class="form-group col-md-3">
                  <input class="form-control form-control-sm" [(ngModel)]="account_name" [ngModelOptions]="{standalone: true}"
                    disabled>
                </div>
              </div>
  
              <div class="col-md-12" style="margin-top: 0.5%;">
                
                <div class="card">
                  <div class="card-body">
                    <table width='100%' id="{{'paymentBankAccountHolderDetaiList' + idIndex2}}" datatable [dtTrigger]="dtTrigger2"
                      [dtOptions]="dtOptions2" class="table table-bordered table-hover">
                      
                      <thead>
                        <tr>
                          <th style="font-family: unicode;">No</th>
                          <th style="font-family: unicode;">Account Holder(Admin)</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let accDetail of paymentBankAccountHolderDetaiList, let i = index">
                          <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                          <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;">
                            {{accDetail.adminName}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
  
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> -->