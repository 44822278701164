import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FunctService } from '@services/funct.service';
import { ApiTokenService } from '@services/api-token.service';
import { ConfirmedValidator } from '@comfirms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common'; 
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-changepassword-detail',
  templateUrl: './changepassword-detail.component.html',
  styleUrls: ['./changepassword-detail.component.css']
})
export class ChangepasswordDetailComponent implements OnInit {

  fieldTextType: boolean= false;
  oldFieldTextType: boolean= false;  
  public adminForm: FormGroup;
  adminId: string;  

  constructor(
    private location: Location, 
    private spinner: NgxSpinnerService, 
    private toastr: ToastrService,  
    private funct: FunctService,  
    private apiTokenServer: ApiTokenService,
    public formBuilder: FormBuilder ,
    private route: ActivatedRoute
    ) {
      this.adminId = this.route.snapshot.paramMap.get('id');
     }

  ngOnInit(): void {
    this.adminForm = this.formBuilder.group({
      id: [this.adminId], 
      old_password: ["", [Validators.required]],
      new_password: ["", [Validators.required]],
      confirm_password: ["", [Validators.required]] , 
    },{ 
      validator: ConfirmedValidator('new_password', 'confirm_password')
    });  
  }
  get f() { return this.adminForm.controls; }
  

  toggleOldFieldTextType() {
    this.oldFieldTextType = !this.oldFieldTextType;
  }
  
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  } 
  async onSubmit(){
    this.spinner.show();
    if(this.adminForm.valid) {     
      let modelStr= JSON.stringify(this.adminForm.value);
      await this.apiTokenServer.post(`${this.funct.ipaddress}authenticate/updatePassword`, modelStr, true)      
      .then(response => {               
        if(response.data.status == 'Success') {
          this.spinner.hide();
          this.toastr.success(response.data.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
          this.location.back();         
        }
        else {
          this.spinner.hide();
          this.toastr.error(response.data.message, '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          
        } 
      })
      .catch(error => {
        this.spinner.hide();
          this.toastr.error(error.response.data.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
      });              
    }
  }
  goBack(){
    this.location.back();
  }  
  
}
