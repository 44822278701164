import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders ,HttpErrorResponse} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DtoService } from '../../service/dto.service';
import { UtilService } from '../../service/util.service';
import { FunctService } from '../../service/funct.service';
import EncryptionDecryptionService from '../../service/encryption-decryption.service';
import { ApiTokenService } from '@services/api-token.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
 
  albumList : any;
  model :any;
  phone_no : any;
  password : any;
  token: any;
  errorMessage : any;
  mobNumberPattern = "^((\\+95-?)|0)?[0-9]{10}$";
  adminDTO;
  constructor(private toastr: ToastrService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private util: UtilService, 
    private router: Router, private storage: LocalStorageService, private funct: FunctService,
    public enc_dec: EncryptionDecryptionService,
    private apiTokenServer: ApiTokenService,) {
  }

  ngOnInit(): void {
    this.model = {
      phone_no: '',
      password: '',
    } //add new
    let isOwnerLoggedIn = localStorage.getItem('isOwnerLoggedIn');
    let token = localStorage.getItem("token_en");  
    if(isOwnerLoggedIn && token != null && token != undefined){        
      this.router.navigate(['/dashboard']);
    }    
  }

  async login() {
    this.spinner.show();
    let mobNumberPattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im;
    if (mobNumberPattern.test(this.phone_no)) { //this.phonNo, this.password
      this.model.phone_no = this.phone_no; //AdminDTO
      this.model.password = this.password;//admin/login
      let model= JSON.stringify(this.model);      
      this.spinner.show(); 
      this.apiTokenServer.login(`${this.funct.ipaddress}authenticate/adLogin`, model)
      .then(async response=> {          
        this.spinner.show();
        console.log(response.data.data)
        if(response.data.statusCode == 200){
              this.spinner.hide();     
              this.util.isLoggedIn = true;   
              localStorage.setItem("token_en", response.data.data);              
              localStorage.setItem('isOwnerLoggedIn', this.util.isLoggedIn);
              this.router.navigate(['/dashboard']);
         }    
         else{
          this.spinner.hide();              
          this.toastr.error(response.data.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });                 
          return false;              
         }    
      })
      .catch(error=> { 
         //ShowError(error.status, this.toastr,error.message);     
      });
      this.spinner.hide();     
    }
    else {
      this.spinner.hide();
      this.toastr.error('Please enter valid phone no.', 'Invalid input!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
  }


  async getAdminProfile() {

    console.log("getAdminProfile :from login")
    await this.apiTokenServer.get(`${this.funct.ipaddress}authenticate/adminProfile`,true)
    .then(async response => {          
        this.adminDTO = JSON.parse(response.data.data);
        localStorage.setItem("adminDTO", response.data.data);     
        window.location.reload();      
        this.spinner.hide();
        console.log(response.data.data)
    })
    .catch(async error => {
      //this.handleAxiosError(error);
      return false;
    });
  }


 
}
