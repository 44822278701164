import { Injectable } from '@angular/core';
import { 
  HttpEvent, HttpRequest, HttpHandler, 
  HttpInterceptor, HttpErrorResponse 
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor(private _router:Router){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)

    .pipe(

      retry(1),

      catchError((error: HttpErrorResponse) => {
        console.log("reach here");
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {

          // client-side error
            console.log('clent error');

        } else {
          if(error.status==405){
    
            this._router.navigate(['system-maintence',error.error.Message,{state: {message: error.error}}]);
          }
   
          // server-side error
          // console.log('server error',error)
        //   errorMessage = `Message: ${ error.error.message ? error.error.message : error.message}`; 
        }

        // window.alert(errorMessage);

        return throwError(error);

      })

    )
  }
}