import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { FunctService } from '@services/funct.service';
import { BehaviorSubject,Observable,Subject } from 'rxjs';
import { ApiTokenService } from '@services/api-token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageContent } from 'src/models/messageContent.model';
import { Group } from 'src/models/group.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private hubConnection: signalR.HubConnection;
  private chatVisible = new BehaviorSubject<boolean>(false);
  private apiUrl = 'https://localhost:7111/api/';
  chatVisible$ = this.chatVisible.asObservable();
  groupList : any=[];
  loginUserId : string = '';
  private messageReceivedSubject = new Subject<any>();
  public messageReceived$ = this.messageReceivedSubject.asObservable();
  private otherMessageReceivedSubject = new Subject<any>();
  public otherMessageReceived$ = this.otherMessageReceivedSubject.asObservable();

  constructor(private funct: FunctService,
    private apiTokenServer: ApiTokenService,
    private toastr: ToastrService,
    private http: HttpClient,
    ) {
      // this.startConnection();

    //   this.hubConnection.on('ReceiveMessage', (messageContent) => {
    //     const adminDtoString =JSON.parse(localStorage.getItem('adminDTO')); 
    //     this.loginUserId = adminDtoString.id.toString();
    //     // this.messageReceivedSubject.next(messageContent);
    //     if (this.loginUserId === messageContent.senderId) {
    //       // Handle messages sent by the current user differently
    //       console.log('Received own message:', messageContent);
    //       this.messageReceivedSubject.next(messageContent);
    //     } else {
    //       // Handle messages from other users
    //       console.log('Received message from others:', messageContent);
    //       this.otherMessageReceivedSubject.next(messageContent);
    //     }
    // });
    }

    private startConnection = () => {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl('https://localhost:7111/hub/chatHub') // Update with your API URL
        .build();
  
      this.hubConnection
        .start()
        .then(() => console.log('Connection started'))
        .catch(err => console.log('Error while starting connection: ' + err));

      this.hubConnection.onclose(error => {
      console.error('SignalR Connection Closed', error);
      setTimeout(() => this.startConnection(), 10000);
      });
    }

    joinGroup = (groupId: string) => {
      if (this.hubConnection) {
        this.hubConnection.invoke('AddToGroup', groupId)
           .catch(err => console.error(err));
     } else {
        console.error('HubConnection is not initialized.');
     }
        // this.hubConnection.invoke('AddToGroup', groupName)
        //     .catch(err => console.error(err));
    }

    
    sendMessageToGroup(groupId: string, messageContent: any): void {
      console.log(messageContent);
      this.hubConnection.invoke('SendMessageToGroup', groupId, messageContent)
      .catch(err => console.error(err));
    }
    
    leaveGroup = (groupId: string) => {
        this.hubConnection.invoke('RemoveFromGroup', groupId)
            .catch(err => console.error(err));
    }

  getGroups(userId: string): Observable<Group[]> {
    const url = `${this.apiUrl}group/getGroupListByUserId?userid=${userId}`;
    return this.http.get<Group[]>(url);
  }

  getMessages(groupId: number): Observable<MessageContent[]> {
    // Implement logic to fetch messages for the given group ID
    const url = `${this.apiUrl}/messages?groupId=${groupId}`;
    return this.http.get<MessageContent[]>(url);
  }
}
