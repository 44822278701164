<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">    
      <form [formGroup]="adminForm" (ngSubmit)="onSubmit()">           
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title" style="font-weight: bold;">Change Password Detail </h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body">    
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Old Password</label>
                        <div class="col-sm-9">
                          <div class="input-group mb-3">
                            <input class="form-control" formControlName="old_password" [type]="oldFieldTextType ? 'text' : 'password'" 
                            [ngClass]="{ 'is-invalid': f.old_password.errors }"> 
                            <div class="input-group-append">
                              <span class="input-group-text">
                                <i class="fa" [ngClass]="{'fa-eye-slash': !oldFieldTextType,'fa-eye': oldFieldTextType}"
                                  (click)="toggleOldFieldTextType()" style="color: #007bff;"></i>
                              </span>
                            </div>   
                          </div>  
                          
                          <div *ngIf="f.old_password.invalid || f.old_password.touched" class="invalid-feedback">
                            <div *ngIf="f.old_password.errors?.required">Old Password is required</div>                           
                          </div>                       
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">New Password</label>
                        <div class="col-sm-9">
                          <div class="input-group mb-3">
                            <input class="form-control" formControlName="new_password" [type]="fieldTextType ? 'text' : 'password'" 
                            [ngClass]="{ 'is-invalid': f.new_password.errors }"> 
                            <div class="input-group-append">
                              <span class="input-group-text">
                                <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                                  (click)="toggleFieldTextType()" style="color: #007bff;"></i>
                              </span>
                            </div>   
                          </div>  
                          <div *ngIf="f.new_password.invalid || f.new_password.touched" class="invalid-feedback">
                            <div *ngIf="f.new_password.errors?.required">Password is required</div>                           
                          </div>                       
                        </div>
                      </div>
                    
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Confirm Password</label>
                        <div class="col-sm-9">
                          <div class="input-group mb-3">
                            <input class="form-control" formControlName="confirm_password" [type]="fieldTextType ? 'text' : 'password'" 
                            [ngClass]="{ 'is-invalid': f.confirm_password.errors }">
                            <div class="input-group-append">
                              <span class="input-group-text">
                                <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                                  (click)="toggleFieldTextType()" style="color: #007bff;"></i>
                              </span>
                            </div>   
                          </div> 
                          <div  *ngIf="f.confirm_password.invalid || f.confirm_password.touched"  class="invalid-feedback">                        
                            <div *ngIf="f.confirm_password.errors?.required">Confirm Password is required</div>  
                            <div *ngIf="f.confirm_password.errors?.confirmedValidator">Password and Confirm Password must be match.</div>     
                          </div>
                        </div>
                      </div>
                      
                      <div class="form-group row">                     
                        <div class="col-sm-3">
                          <button type="submit" class="btn btn-block btn-success" [disabled]="!adminForm.valid" >Save</button>                        
                        </div>
                        <div class="col-sm-3">
                          <button type="button" class="btn btn-block btn-danger" (click)="goBack()">Cancel</button>
                        </div> 
                        <div class="col-sm-6"></div>                       
                      </div>
                      
                    </div>
                    <div class="col-sm-6"></div>                  
                  </div>
                </div>                  
               
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </form> 
    </div>    
  </section>  
</div>