
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
           
            <!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <form>
                <div class="row">
                    <div class="col-md-12">

                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title" style="font-weight: bold;"> Bank Type New </h3>
                            </div>
                            <!-- /.card-header -->
                            <div class="card-body">
                                <form>
                                    <div class="row">
                                        <label class="col-md-2"> Bank Name </label>
                                        <div class="form-group col-md-3">
                                            <input class="form-control form-control-sm" [(ngModel)]="banktypeDTO.name"
                                                [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-6"  style="display: none;">
                                           
                                        </div>
                                    </div>

                                    <div class="row">
                                        <label class="col-md-2"> Country </label>
                                        <div class="form-group col-md-3">
                                            <select (change) = "flagRole()" id = "country_id" class="form-control form-control-sm"
                                            [(ngModel)]="banktypeDTO.country_id" [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let country of CountryList" value = "{{country.id}}" >
                                            {{country.name}}
                                            </option>
                                        </select>
                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-6"  style="display: none;">
                                           
                                        </div>
                                    </div>

                                    <div class="row">
                                        <label class="col-md-2"> Min Accept Amount </label>
                                        <div class="form-group col-md-3">
                                            <input type="number" class="form-control form-control-sm" [(ngModel)]="banktypeDTO.minAcceptAmount"
                                                [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-6"  style="display: none;">
                                           
                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-md-2"> Max Accept Amount </label>
                                        <div class="form-group col-md-3">
                                            <input type="number" class="form-control form-control-sm" [(ngModel)]="banktypeDTO.maxAcceptAmount"
                                                [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-6"  style="display: none;">
                                           
                                        </div>
                                    </div>
                       
                                    <div class="row">
                                        <label class="col-md-2"> Payment App ID </label>
                                        <div class="form-group col-md-3">
                                            <input class="form-control form-control-sm" [(ngModel)]="banktypeDTO.paymentAppId"
                                                [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-6"  style="display: none;">
                                           
                                        </div>
                                    </div>
                       


                                    <div class="row" id="confirmPassword">
                                        <label class="col-md-2"> Bank Logo </label>
                                        <div class="form-group col-md-3">
                                      
                                            <input #file type="file" accept='image/*'
                                                (change)="preview(file.files)" />

                                            <img [src]="imgURL" height="200" width="255" *ngIf="imgURL"
                                                style="margin-top: 5%;">
                                        </div>
                                    </div>

                                    <div class="row">
                                        <label class="col-md-2"> Status </label>
                                        <div class="form-group col-md-3 radio">
                                          <div class="form-group clearfix">
                                            <div class="icheck-success d-inline">
                                              <input type="radio" name="r3" checked id="radioSuccess1" value="ACTIVE"
                                                [(ngModel)]="banktypeDTO.status" [ngModelOptions]="{standalone: true}">
                                              <label for="radioSuccess1">ACTIVE &nbsp;&nbsp;
                                              </label>
                                            </div>
                                            <div class="icheck-success d-inline">
                                              <input type="radio" name="r3" id="radioSuccess2" value="INACTIVE"
                                                [(ngModel)]="banktypeDTO.status" [ngModelOptions]="{standalone: true}">
                                              <label for="radioSuccess2">INACTIVE
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    <div class="row" style="margin-top: 1%;">
                                        <div class="col-md-2 col-sm-6">
                                            <button type="button" class="btn btn-block btn-success"
                                                (click)="goSave()">Save</button>&nbsp;</div>
                                        <div class="col-md-2 col-sm-6">
                                            <button type="button" class="btn btn-block btn-danger"
                                                [routerLink]="['/banktype']"> Cancel </button></div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                </div>


            </form>
            <!-- /.row -->

            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
    <!-- /.content -->
</div>




