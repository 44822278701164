import { Injectable } from '@angular/core';
import * as JsEncryptModule from 'jsencrypt';
import { publicKey } from '../config';
import * as CryptoJS from 'crypto-js';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export default class EncryptionDecryptionService {
  public encryptMod: any;
  public tokenKey: string="7061737323PPAQWE";
  constructor(private storage: LocalStorageService,) { 
    this.encryptMod = new JsEncryptModule.JSEncrypt();
  }

  generateKey() {
    return window.crypto.subtle.generateKey(
      {
        name: 'AES-CBC',
        length: 256
      },
      true,
      ['encrypt', 'decrypt']
      )
      .then(key => {
        return window.crypto.subtle.exportKey('raw', key);
      })
      .then(buffer => {       
        const keyArray = Array.from(new Uint16Array(buffer));
        const keyHex = keyArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        const key= keyHex.substring(0, 16).toLocaleUpperCase();
        return key;
      });
  }

  
  public async encryptDataToAes(aesKey, data) {  
    if(data==undefined||data==""||data.length==0){
      return null;//if with no data
    }
    if(aesKey==undefined||aesKey==""||aesKey.length<16){
      aesKey = await this.generateKey();   //generate a key
    }
    this.encryptMod.setPublicKey(publicKey);
    let aeskeyEncripted = this.encryptMod.encrypt(aesKey);    
    let _key = CryptoJS.enc.Utf8.parse(aesKey);     
    let enc= CryptoJS.AES.encrypt(
        data, _key, {
        keySize: 16,
        iv: _key,
        mode: CryptoJS.mode.CBC,//ECB,
        padding: CryptoJS.pad.Pkcs7
      });
     // return enc.toString();
      return {"secretKey": aeskeyEncripted, "key": aesKey,"data":enc.toString()};  
  }

  public async RSAEncrypt(){ 
    let key = await this.generateKey();   
    this.encryptMod.setPublicKey(publicKey);
    let rsaEnc = this.encryptMod.encrypt(key);    
    return {"secretKey": rsaEnc, "key": key};  
    // this.decryptAES(rsaEnc, key);
   
  }

  public async decryptAES(key, data) {
    let _key = CryptoJS.enc.Utf8.parse(key); 
    return CryptoJS.AES.decrypt(
        data, _key, {
        keySize: 16,
        iv: _key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);  
  }

  public RSAEncryptKey(key){    
    this.encryptMod.setPublicKey(publicKey);
    let enc = this.encryptMod.encrypt(key);
    return enc;    
  }
 
  public async TokenAesKeyEncrypt(data) {   
    let _key = CryptoJS.enc.Utf8.parse(this.tokenKey);     
    let enc= CryptoJS.AES.encrypt(
        data, _key, {
        keySize: 16,
        iv: _key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
     return enc.toString();
    
  }
  
  public async TokenAesKeyDecrypt(data) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenKey); 
    let mykey=
     CryptoJS.AES.decrypt(
        data, _key, {
        keySize: 16,
        iv: _key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);  
      return mykey;
  }


  public async TokenDecryptedData(){
    let token_key= this.storage.retrieve("tokenKey");   
    if(token_key==null|| token_key==undefined){
      return null;
    }
    let key= await this.TokenAesKeyDecrypt(token_key);
    let token= this.storage.retrieve("token");
    let data = await this.decryptAES(key, token);
    if(data == ""){
      data = await this.decryptAES(key, token);
    }
    let newToken = "Bearer "+ data;
    return newToken;
  }
}

